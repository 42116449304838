<script>
  import { Router, Route } from "svelte-routing";

  // components for this layout
  import Sidebar from "../components/Sidebar/Sidebar.svelte";
  import HeaderStats from "../components/Headers/HeaderStats.svelte";
  import FooterAdmin from "../components/Footers/FooterAdmin.svelte";
  import { navigate } from "svelte-navigator";
  import AdminNavbar from "../components/Navbars/AdminNavbar.svelte";

  // pages for this layout
  import Dashboard from "../views/admin/Dashboard.svelte";
  import Settings from "../views/admin/Settings.svelte";
  import Tables from "../views/admin/Tables.svelte";
  import Maps from "../views/admin/Maps.svelte";
  import ComputerList from "../views/admin/ComputerList.svelte";
  import PcManagement from "../views/admin/PcManagement.svelte";
  import ShopManagement from "../views/admin/ShopManagement.svelte";
  import LicenseManagement from "../views/admin/LicenseManagement.svelte";
  import VersionManagement from "../views/admin/VersionManagement.svelte";
  import Download from "../views/admin/Download.svelte";
  import UsersManagement from "../views/admin/UsersManagement.svelte";
  import AdminsManagement from "../views/admin/AdminsManagement.svelte";
  import PermissionsManagement from "../views/admin/PermissionsManagement.svelte";
  import Suppositories from "../views/admin/Suppositories.svelte";
  import Profile from "../views/admin/Profile.svelte";
  import Logs from "../views/admin/Logs.svelte";
  import Demoes from "../views/admin/Demoes.svelte";
  import ExpiringLicense from "../views/admin/ExpiringLicense.svelte";
  import LicenseIndex from "../views/admin/LicenseIndex.svelte";
  import EditUser from "../views/admin/EditUser.svelte";


  export let location;
  export let admin = "";

  let auth;

  if (localStorage.getItem("admin") === null) {
    auth = false;
  } else {
    auth = true;
  }

  if (!auth) {
    navigate("/auth/login");
    window.location.reload();
  }
</script>

{#if auth}
  <div>
    <Sidebar {location} />
    <AdminNavbar />
    <div class="relative md:ml-64 ">
      <!-- <HeaderStats /> -->
        <Router url="admin">
          <Route path="dashboard" component={Dashboard} />
          <Route path="computerList" component={ComputerList} />
          <Route path="pcManagement" component={PcManagement} />
          <Route path="licenseManagement" component={LicenseManagement} />
          <Route path="licenseIndex" component={LicenseIndex} />

          <Route path="editUser" component={EditUser} />

          <Route path="shopManagement" component={ShopManagement} />
          <Route path="downloadList" component={Download} />

          <Route path="versionManagement" component={VersionManagement} />
          <Route path="usersManagement" component={UsersManagement} />
          <Route path="adminsManagement" component={AdminsManagement} />
          <Route
            path="permissionsManagement"
            component={PermissionsManagement}
          />
          <Route path="suppositories" component={Suppositories} />

          <Route path="settings" component={Settings} />
          <Route path="tables" component={Tables} />
          <Route path="maps" component={Maps} />
          <Route path="/profile" component={Profile} />
          <Route path="/logs" component={Logs} />
          <Route path="/demoes" component={Demoes} />
          <Route path="/expiringLicense" component={ExpiringLicense} />

        </Router>
        <!-- <FooterAdmin /> -->
    </div>
  </div>
{/if}
