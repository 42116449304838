<script>
    import DataTable, {
        Head,
        Body,
        Row,
        Cell,
        Pagination,
    } from "@smui/data-table";

    import Button, { Label } from "@smui/button";

    import IconButton from "@smui/icon-button";


    import * as yup from "yup";
    import { Form, Message, isInvalid } from "svelte-yup";

    import "flatpickr/dist/flatpickr.css";

    import Switch from "../../components/Switch.svelte";
    import Api from "../../services/Api";

    let items = [];
    let loadData;
    let versionId, version, major, software, type;
    let addVersion;
    let updateVersion;

    let showModal = false;

    let selected;
    let isActive = false;

    let versionTypes = [
        { id: "client", text: `client` },
        { id: "server", text: `server` },
    ];


    let schema = yup.object().shape({
        software: yup.string().required().max(30).label("software"),
        major: yup.string().required().max(30).label("major"),
        version: yup.string().required().max(30).label("version"),
    });
    let fields = { software: "", major: "", version: "" };
    let submitted = false;
    let isValid;
    function formSubmit() {
        submitted = true;
        isValid = schema.isValidSync(fields);
        if (isValid) {

            if (versionId) {
                updateVersion(versionId);
            } else {
                addVersion();
            }
        }
    }

    function toggleModal() {
        showModal = !showModal;
    }

    let columns = ["id", "software", "type", "major", "version", "isActive"];

    //server side data


    loadData = async () => {
        await Api.get("admin/versions").then((result) => {
            if (!!result) {
                // sort result.data by id
                items = result.data && result.data.sort((a,b) => Number(a.id) < Number(b.id) ? 1 : -1 );
            }
        });

    };

    //update version

    addVersion = async () => {
        await Api.post("admin/versions",{
                software:fields.software,
                type,
                major:fields.major,
                version:fields.version,
                isActive,
            }).then((result) => {
            if (!!result) {
                loadData();
                showModal=false;
            }
        });

    };

    //add version

    updateVersion = async (versionId) => {
        await Api.put(`admin/versions/${versionId}`,{
		            software:fields.software,
		            type,
		            major:fields.major,
		            version:fields.version,
                    isActive,
                }).then((result) => {
            if (!!result) {
               loadData();
               showModal=false;
            }
        });

    };

    loadData();
</script>

<style>
    .form {
        padding: 20px !important;
    }
</style>

{#if showModal}
    <div
        style="margin:auto"
        class=" w-full px-4 md:w-8/12 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center ">
        <div class="relative w-auto my-6 mx-auto max-w-sm">
            <!--content-->
            <div
                class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <!--header-->
                <div
                    class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 class="text-3xl font-semibold">Version</h3>
                    <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        on:click={() => (showModal = false)}>
                        <span
                            class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                        </span>
                    </button>
                </div>
                <!--body-->
                <Form
                class="form"
                {schema}
                {fields}
                submitHandler={formSubmit}
                {submitted}
                color="#b00020">
                <div class="container px-4 mx-auto">
                    <div class="flex flex-wrap">

                        <div class="w-full px-4 sm:w-8/12">

                                <div class="relative w-full">
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="name">
                                        Software
                                    </label>
                                    <input
                                        bind:value={fields.software}
                                        id="Software"
                                        type="text"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="Software" />
                                        <Message name="software" />
                                </div>

                                <div class="relative w-full">
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="type">
                                        type
                                    </label>
                                    <select bind:value={type}>
                                        {#each versionTypes as version}
                                            <option value={version.id}>
                                                {version.text}
                                            </option>
                                        {/each}
                                    </select>
                                </div>

                                <div class="relative w-full">
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="major">
                                        major
                                    </label>
                                    <input
                                        bind:value={fields.major}
                                        id="major"
                                        type="text"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="major" />
                                        <Message name="major" />
                                </div>

                                <div class="relative w-full">
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="version">
                                        version
                                    </label>
                                    <input
                                        bind:value={fields.version}
                                        id="version"
                                        type="text"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="version" />

                                        <Message name="version" />

                                </div>

                                <div class="relative w-full">
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="isActive">
                                        isActive
                                    </label>
                                    <Switch bind:checked={isActive} />
                                </div>

                                <div
                                    class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
                                    style=" margin-top: 20px;
                                padding: 20px;">
                                    <Button
                                        color="secondary"
                                        on:click={() => (showModal = false)}
                                        variant="raised"
                                        style="margin-right:10px">
                                        <Label>Close</Label>
                                    </Button>

                                    <Button
                                        color="secondary"
                                        variant="raised"
                                        letiant="raised">
                                        <Label>Save Changes</Label>
                                    </Button>
                                </div>

                        </div>

                    </div>
                </div>
            </Form>

            </div>
        </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
{/if}

<div class="flex flex-wrap">
    <div class="w-full mb-12 px-4" style="padding-top:6rem;">
<DataTable style="width: 100%;">
    <Head>
        <Row>
            <button
                on:click={()=>{

                    fields.major="";
                    fields.version="";
                    fields.software="";
                    toggleModal()}}
                style="margin:20px"
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button">
                <i class="fas fa-plus" />
                Version
            </button>
        </Row>

        <Row>
            {#each columns as column}
                <Cell>{column}</Cell>
            {/each}
        </Row>
    </Head>
    <Body>
        {#each items as item (item.id)}
            <Row>
                <Cell>{item.id}</Cell>
                <Cell>{item.software}</Cell>
                <Cell>{item.type}</Cell>
                <Cell>{item.major}</Cell>
                <Cell>{item.version}</Cell>
                <Cell>{item.isActive}</Cell>

                <Cell>
                    <IconButton
                        class="fa fa-pen"
                        on:click={() => {
                            versionId = item.id;
                            fields.version = item.version;
                            type = item.type;
                            fields.software = item.software;
                            fields.major = item.major;
                            isActive = item.isActive;
                            toggleModal();
                        }}>
                        <i
                            style="color:green;font-size:14px"
                            class="fa fa-pen"
                            aria-hidden="true" />
                    </IconButton>
                </Cell>
            </Row>
        {/each}
    </Body>
</DataTable>
    </div>
</div>
