<script>
    import { Datatable } from "svelte-simple-datatables";
    import Api from "../../services/Api";

    const settings = {
        columnFilter: false,
        searchInput: false,
        rowsPerPage: 10,
        blocks: {
            paginationButtons: true,
            paginationRowCount: true,
        },
    };

    let getLogs;
    let logs = [];
    let rows;

    getLogs = async (userId, page, limit) => {
        await Api.get(`admin/dashboard/usage`).then((result) => {
            if (!!result) {
                let stats = result.data;

                let keys = Object.keys(result.data);
                let values = Object.values(result.data);
                if (keys.length > 0) {
                    logs = [];
                    for (let i = 0; i < keys.length; i++) {
                        let d = keys[i].split("-");
                        let obj = {
                            month: d[0],
                            day: d[1],
                            dayOfWeek: values[i].dayOfWeek,
                            count: values[i].count,
                        };
                        logs.push(obj);
                    }
                }
            }
        });
    };

    getLogs();
</script>

<div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
>
    <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
            <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 class="font-semibold text-base text-blueGray-700">
                    Logs per week
                </h3>
            </div>
        </div>
    </div>
    <div class="block w-full overflow-x-auto">
        <!-- Projects table -->

        <Datatable {settings} data={logs} bind:dataRows={rows}>
            <thead>
                <th data-key="month">Month</th>
                <th data-key="day">DAY</th>
                <th data-key="dayOfWeek">DAYOFWEEK</th>
                <th data-key="count">COUNT</th>
            </thead>
            <tbody>
                {#if rows}
                    {#each $rows as row}
                        <tr>
                            <td>{row.month}</td>
                            <td>{row.day}</td>
                            <td>{row.dayOfWeek}</td>
                            <td>{row.count}</td>
                        </tr>
                    {/each}
                {/if}
            </tbody>
        </Datatable>
    </div>
</div>
