<script>
    import { link } from "svelte-routing";

    export let caption;
    export let href;

    export let location;

</script>

<li class="items-center">
    <a
            use:link
            href="{href}"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf(href) !== -1
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}">
        <i
                class="fas fa-laptop mr-2 text-sm {location.href.indexOf(href) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"></i>
        {caption}
    </a>
</li>
