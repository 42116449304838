<script>
    import { Tabs, Tab, TabContent } from "carbon-components-svelte";
    import Api from "../../services/Api";
    import { Accordion, AccordionItem } from "carbon-components-svelte";
    import ApplicationStats from "../Headers/ApplicationStats.svelte";
    import { onMount } from "svelte";
    let versionInstalledList;
    let versionList;
    let client, server;
    let clientList = [];
    let serverList = [];
    let active = "Home";
    //get version installed
    onMount(async () => {
        await Api.get("admin/dashboard/versions-installed").then((result) => {
            if (!!result) {
                versionList = result.data;
                client = versionList.client;
                server = versionList.server;
                let clientKeys = Object.keys(client);
                let clientValues = Object.values(client);
                let serverKeys = Object.keys(server);
                let serverValues = Object.values(server);
                clientList = [];
                //client
                if (clientKeys.length > 0) {
                    for (let i = 0; i < clientKeys.length; i++) {
                        let obj = {
                            version: clientKeys[i],
                            items: clientValues[i].items,
                            count: clientValues[i].count,
                        };
                        clientList.push(obj);
                    }
                }

                //server
                if(serverKeys.length>0){

                    for (let i = 0; i < serverKeys.length; i++) {
                    let obj = {
                        version: serverKeys[i],
                        items: serverValues[i].items,
                        count: serverValues[i].count,
                    };
                    serverList.push(obj);
                }

                }
               
                console.log("clientList...", clientList);

                console.log("server...",serverList);
            }
        });
    });

    // versionInstalledList
</script>

{#if (clientList.length > 0 && serverList.length>0)}
    <div
        class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
            <div class="flex flex-wrap items-center">
                <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-base text-blueGray-700">
                        Version Installed
                    </h3>
                </div>
            </div>
        </div>
        <div class="block w-full overflow-x-auto">
            <Tabs>
                <Tab label="SERVER" />
                <Tab label="CLIENT" />

                <div slot="content">
                    <TabContent><Accordion>
                        {#each serverList as server}
                            <AccordionItem
                                title="{server.version}({server.count})">
                                {#if server.items && server.items.length > 0}
                                    {#each server.items as item}
                                        <li style="color:{item.color}">
                                            {item.userInfo}
                                        </li>
                                    {/each}
                                {/if}
                            </AccordionItem>
                        {/each}
                    </Accordion></TabContent>
                    <TabContent>
                        <Accordion>
                            {#each clientList as client}
                                <AccordionItem
                                    title="{client.version}({client.count})">
                                    {#if client.items && client.items.length > 0}
                                        {#each client.items as item}
                                            <li style="color:{item.color}">
                                                {item.userInfo}
                                            </li>
                                        {/each}
                                    {/if}
                                </AccordionItem>
                            {/each}
                        </Accordion>
                    </TabContent>
                </div>
            </Tabs>
        </div>
    </div>
{/if}
