<script>
    import { Accordion, AccordionItem } from "carbon-components-svelte";
    import List from "@smui/list";
    import Api from "../../services/Api";
    import DownloadItem from '../../components/Items/DownloadItem.svelte';

    let loadData;
    let items = [];

    //server side data

    loadData = async () => {
        await Api.get("admin/downloads").then((result) => {
            if (!!result) {
                items = [];

                result.data.server.forEach((element) => {
                    for (const [key, value] of Object.entries(element)) {
                        let obj = {
                            version: key,
                            versionList: value,
                        };
                        items.push(obj);
                    }
                });
            }
        });
    };

    loadData();
</script>

<div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4" style="background:#fff;padding-top:5rem;">
        <h3 style="padding:20px;font-weight: 700;">Download List</h3>
        <Accordion size="xl">
            {#each items as item}
                <AccordionItem title="Version {item.version}">
                    <List class="demo-list">
                        {#each item.versionList as listItem (listItem.title)}
                            <DownloadItem itemData="{listItem}" />
                        {/each}
                    </List>
                </AccordionItem>
            {/each}
        </Accordion>
    </div>
</div>
