<script>
    import DataTable, {
        Head,
        Body,
        Row,
        Cell,
        Pagination,
    } from "@smui/data-table";

    import IconButton from "@smui/icon-button";

    import Api from "../../services/Api";
    import Select from "svelte-select";
    import Button, { Label } from "@smui/button";
    import SelectPagination , {Option} from "@smui/select";

    import LinearProgress from "@smui/linear-progress";

    let rowsPerPage = 10;
    let currentPage = 1;
    let items = [];
    let loadData;
    let isActiveValue = null,
        isAssociateValue = null;

    let total;

    let collection = [
        { value: 1, label: "one" },
        { value: 2, label: "two" },
        { value: 3, label: "three" },
    ];

    $: start = (currentPage - 1) * rowsPerPage;
    $: end = Math.min(start + rowsPerPage, total);
    $: lastPage = Math.max(Math.ceil(total / rowsPerPage), 0);

    $: if (currentPage > lastPage) {
        currentPage = lastPage;
    }
    let isActiveItems = [
        { value: "-1", label: "NOT SELECTED" },
        { value: "true", label: "true" },
        { value: "false", label: "false" },
    ];
    let isAssociatedItems = [
        { value: "-1", label: "NOT SELECTED" },
        { value: "true", label: "associated" },
        { value: "false", label: "not associated" },
    ];

    function handleIsActiveSelect(event) {
        console.log("selected item", event.detail);
        isActiveValue = event.detail.value;
    }

    function handleIsAssociatedSelect(event) {
        console.log("selected item", event.detail);
        isAssociateValue = event.detail.value;
    }
    function filter() {
        if (isActiveValue != "-1" && isAssociateValue == "-1") {
            console.log("if111");
            loadData(currentPage, rowsPerPage, isActiveValue, null);
        }
        if (isAssociateValue != "-1" && isActiveValue == "-1") {
            console.log("if2222");
            loadData(currentPage, rowsPerPage, null, isAssociateValue);
        }
        if (isAssociateValue != "-1" && isActiveValue != "-1") {
            console.log("if3333");
            loadData(currentPage, rowsPerPage, isActiveValue, isAssociateValue);
        }
        // if(isActiveValue=="-1" && isAssociateValue !="-1"){
        //     loadData(currentPage, rowsPerPage, null, isAssociateValue);
        // }
        //  if(isActiveValue!="-1" && isAssociateValue =="-1"){
        //     loadData(currentPage, rowsPerPage, isActiveValue, null);


        // }
        // } else {
        //     console.log("if444444");
        //     loadData(currentPage, rowsPerPage, null, null);
        // }
    }

    //server side data
    let loaded = false;
    loadData = async (page, limit, isActive, isAssociated) => {
        loaded = false;
        let link;
        if (isActive != null && isAssociated == null ) {
            console.log("if11111");
            link = `admin/computers?page=${page}&limit=${limit}&isActive=${isActive}`;
        } else if (isAssociated != null && isActive == null) {
            link = `admin/computers?page=${page}&limit=${limit}&isAssociated=${isAssociated}`;
        } else if (isActive != null && isAssociated != null) {
            console.log("indise thissss...");
            link = `admin/computers?page=${page}&limit=${limit}&isActive=${isActive}&isAssociated=${isAssociated}`;
        } else if (isActive == null && isAssociated == null) {
            link = `admin/computers?page=${page}&limit=${limit}`;
        }

        await Api.get(link).then((result) => {
            if (!!result) {
                total = result.totalCount;
                items = result.data;
                loaded = true;
            }
        });
    };

    loadData(1, rowsPerPage, null, null);
    let columns = [
        "customer",
        "description",
        "swType",
        "version",
        "Associated",
        "Active",
    ];
</script>


<div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4" style="padding-top:5rem;">

<div class="flex flex-wrap mb-2">
    <div class="w-full px-4 flex-1">
        <div class="relative w-full">
            <label for="active">Is Active:</label>
            <Select items={isActiveItems} on:select={handleIsActiveSelect} />
        </div>
    </div>

    <div class="w-full px-4 flex-1">
        <div class="relative w-full">
            <label for="associated">Is Associated:</label>
            <Select
                items={isAssociatedItems}
                on:select={handleIsAssociatedSelect} />
        </div>
    </div>

    <div class="w-full px-4 flex-1">
        <div class="relative w-full" style="margin-top: 20px;">
            <Button
                color="secondary"
                on:click={() => filter()}
                variant="raised"
                style="margin-right:10px">
                <Label>filter</Label>
            </Button>
        </div>
    </div>
</div>

        <Pagination>
            <div slot="rowsPerPage">
                <Label>Rows Per Page</Label>
                <SelectPagination variant="outlined" bind:value={rowsPerPage} on:blur={() => loadData(currentPage, rowsPerPage)} noLabel>
                    <Option value={10}>10</Option>
                    <Option value={25}>25</Option>
                    <Option value={50}>50</Option>
                </SelectPagination>
            </div>
            <div slot="total">{start}-{end} of {total}</div>

            <IconButton
                    class="material-icons"
                    action="first-page"
                    title="First page"
                    on:click={() => (currentPage = 1)}
                    disabled={currentPage === 1}>
                first_page
            </IconButton>
            <IconButton
                    class="material-icons"
                    action="prev-page"
                    title="Prev page"
                    on:click={() => {
                        currentPage = currentPage - 1;
                        loadData(currentPage, rowsPerPage);
                    }}
                    disabled={currentPage === 1}>
                chevron_left
            </IconButton>
            <IconButton
                    class="material-icons"
                    action="next-page"
                    title="Next page"
                    on:click={() => {
                        currentPage = currentPage + 1;
                        loadData(currentPage, rowsPerPage);
                    }}
                    disabled={currentPage === lastPage}>
                chevron_right
            </IconButton>
            <IconButton
                    class="material-icons"
                    action="last-page"
                    title="Last page"
                    on:click={() => (currentPage = lastPage)}
                    disabled={currentPage === lastPage}>
                last_page
            </IconButton>
        </Pagination>

        <DataTable table$aria-label="Todo list" style="width: 100%;">
            <Head>
                <Row>
                    {#each columns as column}
                        <Cell>{column}</Cell>
                    {/each}
                </Row>
            </Head>
            <Body>
                {#each items as item}
                    <Row>
                        <Cell>{item.companyName}</Cell>
                        <Cell>{item.description}</Cell>
                        <Cell>{item.swType}</Cell>
                        <Cell>{item.swVersion}</Cell>
                        <Cell>{item.isAssociated ? '1' : '0'}</Cell>
                        <Cell>{item.isActive ? '1' : '0'}</Cell>
                    </Row>
                {/each}
            </Body>

            <LinearProgress
                    indeterminate
                    bind:closed={loaded}
                    aria-label="Data is being loaded..."
                    slot="progress"
            />
        </DataTable>
    </div>
</div>
