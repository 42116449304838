<script>
    import DataTable, {
        Head,
        Body,
        Row,
        Cell,
        Pagination,
    } from "@smui/data-table";
    import qs from "qs";

    import IconButton from "@smui/icon-button";
    import Select, { Option } from "@smui/select";

    import Api from "../../services/Api";

    import Button, { Label } from "@smui/button";
    import LinearProgress from "@smui/linear-progress";
    import {Checkbox} from "carbon-components-svelte";

    let rowsPerPage = 10;
    let currentPage = 1;
    let items = [];
    let loadData;
    let sort = "expirationDate";
    let sortDirection = "ascending";
    let customerId;

    let daysUntilExpiration = 30;
    let activeOnly = true;

    let total;

    $: start = currentPage * rowsPerPage;
    $: end = Math.min(start + rowsPerPage, items.length);
    $: slice = items.slice(start, end);
    $: lastPage = Math.max(Math.ceil(items.length / rowsPerPage) - 1, 0);
    $: if (currentPage > lastPage) {
        currentPage = lastPage;
    }

    //server side data

    let isLoadingData = false;
    loadData = async () => {
        isLoadingData = true;
        const params = qs.stringify({
            daysUntilExpiration,
            activeOnly: activeOnly,
        })

        await Api.get(`admin/dashboard/expiring-licenses?${params}`).then((result) => {
            if (!!result) {
                // total = result.totalCount;
                items = result.data;
                total = items.length;

            }
        });
        isLoadingData = false;
    };

    function handleSort() {
        items.sort((a, b) => {
            sort = sort === 'expirationDate' ? 'date' : sort;
            const [aVal, bVal] = [a[sort], b[sort]][
                sortDirection === 'ascending' ? 'slice' : 'reverse'
                ]();
            if (typeof aVal === 'string') {
                return aVal.localeCompare(bVal);
            }
            return aVal - bVal;
        });
        items = items;
    }

    let columns = ["client", "pc", "type", "status", "expirationDate", "isActive"];
    loadData();
</script>

<div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4" style="padding-top:5rem;">

        <div class="">
            <h1 class="text-3xl font-semibold">Expiring Licenses</h1>

            <div class="flex flex-col gap-4 p-4 mt-8 bg-white">
                <div>
                    <Label class="mr-2">Show licenses that will expire in</Label>
                    <Select variant="outlined" bind:value={daysUntilExpiration} on:MDCSelect:change={() => {loadData(currentPage, rowsPerPage);}}>
                        <Option value={30}>30 days</Option>
                        <Option value={60}>60 days</Option>
                        <Option value={90}>90 days</Option>
                    </Select>
                </div>

                <div class="mt-2">
                    <Checkbox labelText="Only Active licenses" variant="outlined" bind:checked={activeOnly} on:check={() => {loadData(currentPage, rowsPerPage);}}>
                    </Checkbox>
                </div>

            </div>

        </div>

        <Pagination slot="paginate">
            <div slot="rowsPerPage">
                <Label>Rows Per Page</Label>

                <Select variant="outlined" bind:value={rowsPerPage} on:blur={() => loadData(currentPage, rowsPerPage)} noLabel>
                    <Option value={10}>10</Option>
                    <Option value={25}>25</Option>
                    <Option value={100}>100</Option>
                </Select>
            </div>
            <div slot="total">{start + 1}-{end} of {total}</div>

            <IconButton
                    class="material-icons"
                    action="first-page"
                    title="First page"
                    on:click={() => (currentPage = 0)}
                    disabled={currentPage === 0}>first_page</IconButton
            >
            <IconButton
                    class="material-icons"
                    action="prev-page"
                    title="Prev page"
                    on:click={() => currentPage--}
                    disabled={currentPage === 0}>chevron_left</IconButton
            >
            <IconButton
                    class="material-icons"
                    action="next-page"
                    title="Next page"
                    on:click={() => currentPage++}
                    disabled={currentPage === lastPage}
            >chevron_right</IconButton
            >
            <IconButton
                    class="material-icons"
                    action="last-page"
                    title="Last page"
                    on:click={() => (currentPage = lastPage)}
                    disabled={currentPage === lastPage}>last_page</IconButton
            >
        </Pagination>

        <DataTable
                sortable
                bind:sort
                bind:sortDirection
                on:MDCDataTable:sorted={handleSort}
                style="width: 100%;">
            <div slot="progress">
                <LinearProgress
                        class="w-full"
                        indeterminate
                        closed={!isLoadingData}
                        aria-label="Data is being loaded..."
                />
            </div>
            <Head>

                <Row>
                  <br/>
                </Row>
                <Row>
                    {#each columns as column}
                        <Cell columnId="{column}">
                            <Label>{column
                                .replace(/([a-z])([A-Z])/g, '$1 $2')
                                .replace(/^./, function (match) { return match.toUpperCase(); })}</Label>
                            <IconButton class="material-icons">arrow_upward</IconButton>
                        </Cell>
                    {/each}
                </Row>
            </Head>
            <Body>
                {#each slice as item}
                    <Row>
                        <Cell>{item.client}</Cell>
                        <Cell>{item.pc}</Cell>
                        <Cell>{item.type}</Cell>
                        <Cell>{item.status}</Cell>
                        <Cell>{item.date}</Cell>
                        <Cell>
                            <i class="material-icons {item.isActive ? ' text-green-500' : 'text-red-600'} ">{item.isActive ? 'check' : 'close'}</i>
                        </Cell>
                    </Row>
                {/each}
            </Body>

        </DataTable>
    </div>
</div>
