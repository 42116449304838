<script>
  import Button, { Label } from "@smui/button";
  import { Form, Message, isInvalid } from "svelte-yup";
  import * as yup from "yup";
  import Api from "../../services/Api";
  import Notifications from "svelte-notify";
  import { notify } from "svelte-notify";

  const team2 = "/assets/img/team-2-800x800.jpg";
  // export let location;

  let firstName = "",
    lastName,
    email,
    password;
  let updateAdmin;

  let adminInfo;

  function setData() {
    if (localStorage.getItem("admin") !== null) {
      adminInfo = JSON.parse(localStorage.getItem("admin"));
      lastName = adminInfo.lastName;
      email = adminInfo.email;
      firstName = adminInfo.firstName;
    }
  }

  updateAdmin = async () => {
    await Api.put(`admin/profile`, {
      firstName,
      lastName,
      email,
      password,
    }).then((result) => {
      if (!!result) {
        localStorage.setItem("admin", JSON.stringify(result.data));

        notify({
          title: "successful",
          message: "admin updated successfully",
          timeout: 1000,
          type: "success",
        });
      }
    });
  };

  let schema = yup.object().shape({
    name: yup.string().required().max(30).label("Name"),
  });
  let fields = { name: "" };
  let submitted = false;
  let isValid = true;
  function formSubmit() {
    submitted = true;
    //isValid = schema.isValidSync(fields);
    if (isValid) {
      updateAdmin();
    }
  }

  setData();
</script>

<div>

  <section class="relative block h-500-px">
    <Notifications />

    <div
      class="absolute top-0 w-full h-full bg-center bg-cover"
      style="
          background-image: url(https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80);
        ">
      <span
        id="blackOverlay"
        class="w-full h-full absolute opacity-50 bg-black" />
    </div>
    <div
      class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
      style="transform: translateZ(0);">
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0">
        <polygon
          class="text-blueGray-200 fill-current"
          points="2560 0 2560 100 0 100" />
      </svg>
    </div>
  </section>
  <section class="relative py-16 ">
    <div class="container mx-auto px-4">
      <div
        class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
        <div class="px-6">
          <div class="flex flex-wrap justify-center">
            <div class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
              <div class="relative">
                </div>
            </div>
            <div
              class="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center" />
            <div class="w-full lg:w-4/12 px-4 lg:order-1">
              <div class="flex justify-center py-4 lg:pt-4 pt-8" />
            </div>
          </div>
          <Form
            class="form"
            {schema}
            {fields}
            submitHandler={formSubmit}
            {submitted}
            color="#b00020">
            <div class="w-full px-4 flex-1" style="margin-top:50px">
              <div class="relative w-full">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="firstName">
                  firstName
                </label>
                <input
                  bind:value={firstName}
                  id="firstName"
                  type="text"
                  class=" px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="firstName" />
              </div>
            </div>

            <div class="w-full px-4 flex-1">
              <div class="relative w-full">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="lastName">
                  lastName
                </label>
                <input
                  bind:value={lastName}
                  id="lastName"
                  type="text"
                  class=" px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="lastName" />
              </div>
            </div>

            <div class="w-full px-4 flex-1">
              <div class="relative w-full">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="email">
                  email
                </label>
                <input
                  bind:value={email}
                  id="email"
                  type="email"
                  class=" px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="email" />
              </div>
            </div>

            <div class="w-full px-4 flex-1">
              <div class="relative w-full">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="password">
                  password
                </label>
                <input
                  bind:value={password}
                  id="password"
                  type="password"
                  class=" px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="password" />
              </div>
            </div>

            <div
              class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              <!-- <button
              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button">
              Save Changes
            </button> -->
              <Button class="my-2" color="secondary" variant="raised" letiant="raised">
                <Label>Save Changes</Label>
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </section>
</div>
