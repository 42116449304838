<script>
    import { DataTable } from "carbon-components-svelte";
    import "carbon-components-svelte/css/g10.css";
    import Dialog, { Title, Content, Actions } from "@smui/dialog";
    import Button, { Label } from "@smui/button";

    import IconButton from "@smui/icon-button";
    import {  CUSTOMER } from "../../stores/store.js";

    import * as yup from "yup";
    import { Form, Message, isInvalid } from "svelte-yup";
    import Chip, { Set, Text } from "@smui/chips";
    import Switch from "../../components/Switch.svelte";
    import Select from "svelte-select";
    import Api from "../../services/Api";

    let addRole;
    let permList;
    let selectedPerm = [];

    let firstName, lastName, password, email, isActive, note;
    let title;

    $: perms = [];
    let updatedId;
    let removeRole;
    let updateRole;
    let rowsPerPage = 10;
    let currentPage = 1;
    let total;
    let items = [];
    let loadData;
    let getPermsList;
    let open = false;

    let description;
    let roleId;

    let showModal = false;

    function toggleModal() {
        showModal = !showModal;
    }

    //for validations
    let schema = yup.object().shape({
        title: yup.string().required().max(50).label("title"),
    });
    let fields = { title: "" };
    let submitted = false;
    let isValid;
    function formSubmit() {
        submitted = true;
        isValid = schema.isValidSync(fields);
        if (isValid) {
            if (updatedId) {
                updateRole(updatedId);
            } else {
                addRole();
            }
        }
    }

    $: start = (currentPage - 1) * rowsPerPage;
    $: end = Math.min(start + rowsPerPage, total);
    $: lastPage = Math.max(Math.ceil(total / rowsPerPage), 0);

    $: if (currentPage > lastPage) {
        currentPage = lastPage;
    }

    //server side data


    getPermsList = async () => {
        await Api.get("admin/permissions").then((result) => {
            if (!!result) {
                let perms = result.data;
                permList = [];
                perms.forEach((element) => {
                    let obj = { value: element.id, label: element.title };
                    permList.push(obj);
                });
            }
        });

    }

    function handleSelect(event) {
        // console.log("selected item", event.detail);

        if (!!event.detail && event.detail.length > 0) {
            perms = [];

            event.detail.forEach((element) => {
                perms.push(element.value);
            });
            console.log("selected item", perms);
        }

        // .. do something here 🙂
    }

    loadData = async () => {

        await Api.get("admin/roles").then((result) => {
            if (!!result) {
                items = result.data;
            }
        });

    };

    //create shop

    addRole = async () => {

        await Api.post("admin/roles", {
                    title: fields.title,
                    permissions: perms,
                }).then((result) => {
            if (!!result) {
                loadData();
                toggleModal();
            }
        });

    };

    //remove shop

    removeRole = async (id) => {
        await Api.delete(`admin/roles/${id}`).then((result) => {
            if (!!result) {
                loadData();
                showModal = false;
            }
        });

    };

    //update role

    updateRole = async (id) => {

        await Api.put(`admin/roles/${id}`, {
                title: fields.title,
                permissions: perms,
            }).then((result) => {
            if (!!result) {
                loadData();
                showModal = false;
            }
        });

    };

    loadData();
    getPermsList();

    CUSTOMER.subscribe((value) => {
        console.log("customer in store is..", value);
    });
</script>

<style>
    .invalid {
        border-color: red !important;
    }
</style>

{#if showModal}
    <div
        style="margin:auto;top:50px;z-index:20000000"
        class=" w-full px-4 md:w-8/12 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center ">
        <div class="relative w-auto my-6 mx-auto max-w-sm" style="">
            <!--content-->
            <div
                class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none modal-height">
                <!--header-->
                <div
                    class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 class="text-3xl font-semibold">Role</h3>
                    <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        on:click={() => (showModal = false)}>
                        <span
                            class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                        </span>
                    </button>
                </div>
                <!--body-->
                <Form
                    class="form"
                    {schema}
                    {fields}
                    submitHandler={formSubmit}
                    {submitted}
                    color="#b00020">
                    <div class="container px-4 mx-auto">
                        <div class="flex flex-wrap" style="height:30%">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="title">
                                        Title
                                    </label>
                                    <input
                                        bind:value={fields.title}
                                        id="title"
                                        type="text"
                                        class=" px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="title" />
                                    <Message name="title" />
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="Permissions">
                                        Permissions
                                    </label>

                                    <Select
                                        items={permList}
                                        value={selectedPerm}
                                        isMulti={true}
                                        on:select={handleSelect} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--footer-->
                    <div
                        class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <Button
                            color="secondary"
                            on:click={() => (showModal = false)}
                            variant="raised"
                            style="margin-right:10px">
                            <Label>Close</Label>
                        </Button>

                        <Button
                            color="secondary"
                            variant="raised"
                            letiant="raised">
                            <Label>Save Changes</Label>
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    </div>

    <div class="opacity-25 fixed inset-0 z-40 bg-black overlay" />
{/if}
<!--dialog-->
<Dialog
    bind:open
    aria-labelledby="simple-title"
    aria-describedby="simple-content">
    <!-- Title cannot contain leading whitespace due to mdc-typography-baseline-top() -->
    <Title id="simple-title">Remove Rple</Title>
    <Content id="simple-content">Are You Sure?</Content>
    <Actions>
        <Button>
            <Label>No</Label>
        </Button>
        <Button
            on:click={() => {
                console.log('roleId is..', roleId);
                removeRole(roleId);
            }}>
            <Label>Yes</Label>
        </Button>
    </Actions>
</Dialog>

<div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4" style="padding-top:5rem;">
        <button
            on:click={() => {
                fields.title = '';
                selectedPerm = [];
                toggleModal();
            }}
            style="margin:20px"
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button">
            <i class="fas fa-plus" />
            Role
        </button>
        <DataTable
            zebra
            title="Role List"
            size="short"
            expandable
            headers={[{ key: 'title', value: 'Title' }, { key: 'actions', value: 'Actions' }]}
            rows={items}>
            <span slot="cell" let:cell let:row>
                {#if cell.key === 'actions'}
                    <IconButton
                        action=""
                        title=""
                        on:click={() => {
                            open = true;
                            roleId = row.id;
                        }}>
                        <i
                            style="color:red;font-size:14px"
                            class="fa fa-trash"
                            aria-hidden="true" />
                    </IconButton>

                    <IconButton
                        class="fa fa-pen"
                        on:click={() => {
                            updatedId = row.id;
                            fields.title = row.title;

                            if (row.permissions.length > 0) {
                                selectedPerm = [];
                                row.permissions.forEach((element) => {
                                    let obj = { value: element.id, label: element.title };
                                    selectedPerm.push(obj);
                                });
                            }
                            toggleModal();
                        }}>
                        <i
                            style="color:green;font-size:14px"
                            class="fa fa-pen"
                            aria-hidden="true" />
                    </IconButton>
                {:else}{cell.value}{/if}
            </span>
            <div slot="expanded-row" let:row>
                <span style="color:red">permissions:</span>
                <Set chips={row.permissions} let:chip>
                    <!-- Note: the `chip` property is required! -->
                    <Chip {chip} shouldRemoveOnTrailingIconClick={false}>
                        <Text tabindex="0">{chip.title}</Text>
                    </Chip>
                </Set>
            </div>
        </DataTable>
    </div>
</div>
