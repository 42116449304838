<script>
  // core components
  import UserDropdown from "../Dropdowns/UserDropdown.svelte";
</script>

<style>

  .hair-nav{
    background: #fff;
    color: #000;
    -webkit-box-shadow: 0px 7px 10px -4px #000000;
    box-shadow: 0px 7px 10px -4px #000000;
    height: 50px;
}
  
</style>

<!-- Navbar -->
<nav
  class=" hair-nav absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
  <div
    class="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
    <!-- Brand -->
    <a
      class="text-black text-sm uppercase hidden lg:inline-block font-semibold"
      href="#pablo"
      on:click={(e) => e.preventDefault()}>
      Touch Hair
    </a>

    <!-- User -->
    <ul class="flex-col md:flex-row list-none items-center hidden md:flex">
      <UserDropdown />
    </ul>
  </div>
</nav>
<!-- End Navbar -->
