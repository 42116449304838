

<script>
  import { Router, Route } from "svelte-routing";

  // components for this layout
  // import AdminNavbar from "components/Navbars/AdminNavbar.svelte";
  import Sidebar from "../components/Sidebar/Sidebar.svelte";
  import HeaderStats from "../components/Headers/HeaderStats.svelte";
  import FooterAdmin from "../components/Footers/FooterAdmin.svelte";
  import Login from "../views/auth/Login.svelte";

  // pages for this layout
  import Dashboard from "../views/admin/Dashboard.svelte";
  import Settings from "../views/admin/Settings.svelte";
  import Tables from "../views/admin/Tables.svelte";

  // import { authenticated } from "../views/auth/stores/store";
  

  
  
  import { navigate } from "svelte-navigator";
import ComputerList from "./admin/ComputerList.svelte";
import ApplicationStats from "../components/Headers/ApplicationStats.svelte";
import AdminNavbar from "../components/Navbars/AdminNavbar.svelte";


  export let location;
  export let admin = "";
 
  let auth;

if (localStorage.getItem("admin") === null) {
  auth = false;
} else {
  auth = true;
}

if (!auth) {
  navigate("/auth/login");
  window.location.reload();
}

  


</script>

{#if auth}
  <div>
    <Sidebar {location} />
    <div class="relative md:ml-64 bg-blueGray-100">
      <!-- <AdminNavbar /> -->
      <AdminNavbar />
      <HeaderStats />
      
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <Router url="admin">
          <Route path="dashboard" component={Dashboard} />
          <Route path="computerList" component={ComputerList} />
          <Route path="settings" component={Settings} />
          <Route path="tables" component={Tables} />
     
        </Router>
        <FooterAdmin />
      </div>
    </div>
  </div>
  {/if}
  

