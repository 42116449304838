<script>
  import {link} from "svelte-routing";
  import {CUSTOMER} from "../../stores/store.js";

  // core components
  import NotificationDropdown from "../Dropdowns/NotificationDropdown.svelte";
  import UserDropdown from "../Dropdowns/UserDropdown.svelte";
  import Api from "../../services/Api";

  import Tags from "svelte-tags-input";
  import {Authenticated} from "../../services/Authenticated";
  import SideBarItem from "./SideBarItem.svelte";

  let customerList;
  let customer;
  let customerId;

  let loadcustomers;

  let collapseShow = "hidden";
  const authenticated = new Authenticated();

  function handleTagsFlags(event) {
    console.log("event is..", event);
    if (event.detail && event.detail.tags.length > 0) {
      customerId = event.detail.tags[0].id;
      console.log("customerId is..", customerId);
      customerList.forEach((element) => {
        if (element.id == customerId) {
          customer = element;
        }
      });
      console.log("customer is...", customer);
      CUSTOMER.set(customer);
    }
  }

  function toggleCollapseShow(classes) {
    collapseShow = classes;
  }

  export let location;

  loadcustomers = async () => {
    await Api.get("admin/users?page=1&limit=400").then((result) => {
      if (!!result) {
        let data = result.data;
        console.log("result...", result);

        customerList = data.map((person) => ({
          id: person.id,
          name:
                  person.companyName + " " + person.firstName + " " + person.lastName,
        }));
      }
    });
  };

  loadcustomers();
  console.log("customer list...", customerList);
</script>

<nav
  class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
  style="z-index:5000"
>
  <div
    class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
  >
    <!-- Toggler -->
    <button
      class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
      type="button"
      on:click={() => toggleCollapseShow("bg-white m-2 py-3 px-6")}
    >
      <i class="fas fa-bars"></i>
    </button>
    <!-- Brand -->

    <img src="/assets/img/logo_head.png" alt="logo" />
    <hr class="my-4 md:min-w-full" />
    <span>Customer Search:</span>
    <Tags
      on:tags={handleTagsFlags}
      autoComplete={customerList}
      autoCompleteKey={"name"}
      onlyAutocomplete="true"
      maxTags={1}
    />

    <!-- User -->
    <ul class="md:hidden items-center flex flex-wrap list-none">
      <li class="inline-block relative">
        <NotificationDropdown />
      </li>
      <li class="inline-block relative">
        <UserDropdown />
      </li>
    </ul>
    <!-- Collapse -->
    <div
      class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}"
    >
      <!-- Collapse header -->
      <div
        class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
      >
        <div class="flex flex-wrap">
          <div class="w-6/12">
            <a
              use:link
              class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
              href="/"
            >
              Touch Hair
            </a>
          </div>
          <div class="w-6/12 flex justify-end">
            <button
              type="button"
              class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              on:click={() => toggleCollapseShow("hidden")}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- Form -->
      <form class="mt-6 mb-4 md:hidden">
        <div class="mb-3 pt-0">
          <input
            type="text"
            placeholder="Search"
            class="border-0 px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
          />
        </div>
      </form>

      <ul class="md:flex-col md:min-w-full flex flex-col list-none">


        <li class="items-center">
          <a
            use:link
            href="/admin/dashboard"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf(
              '/admin/dashboard'
            ) !== -1
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                '/admin/dashboard'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"></i>
            Dashboard
          </a>
        </li>

      </ul>

      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <h6
        class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
      >
        Customer management
      </h6>
      <!-- Navigation -->

      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        {#if authenticated.can('read_computer')}
        <li class="items-center">
          <a
            use:link
            href="/admin/pcManagement"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf(
              '/admin/pcManagement'
            ) !== -1
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                '/admin/pcManagement'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"></i>
            Pc Management
          </a>
        </li>
        {/if}
        {#if authenticated.can('read_shop')}
        <li class="items-center">
          <a
            use:link
            href="/admin/shopManagement"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf(
              '/admin/shopManagement'
            ) !== -1
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-university mr-2 text-sm {location.href.indexOf(
                '/admin/shopManagement'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"></i>
            Shop Management
          </a>
        </li>
        {/if}

        {#if authenticated.can('read_license')}
        <li class="items-center">
          <a
            use:link
            href="/admin/licenseManagement"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf(
              '/admin/licenseManagement'
            ) !== -1
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-file mr-2 text-sm {location.href.indexOf(
                '/admin/licenseManagement'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"></i>
            License Management
          </a>
        </li>
          {/if}

        {#if authenticated.can('update_user')}
          <SideBarItem caption="edit customer" href="/admin/editUser" {location} />
        {/if}


        {#if authenticated.can('read_log')}
        <li class="items-center">
          <a
                  use:link
                  href="/admin/logs"
                  class="text-xs uppercase py-3 font-bold block {location.href.indexOf(
              '/admin/logs'
            ) !== -1
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
                    class="fas fa-tv mr-2 text-sm {location.href.indexOf(
                '/admin/logs'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"></i>
            Logs
          </a>
        </li>
          {/if}


        <hr class="my-4 md:min-w-full" />

        {#if authenticated.can('read_logs')}
        <li class="items-center">
          <a
            use:link
            href="/admin/versionManagement"
            class="text-xs uppercase py-3 font-bold block {location.href.indexOf(
              '/admin/versionManagement'
            ) !== -1
              ? 'text-red-500 hover:text-red-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-laptop mr-2 text-sm {location.href.indexOf(
                '/admin/versionManagement'
              ) !== -1
                ? 'opacity-75'
                : 'text-blueGray-300'}"></i>
            Version Management
          </a>
        </li>
          {/if}

          {#if authenticated.can('read_license')}
          <li class="items-center">
            <a
              use:link
              href="/admin/licenseIndex"
              class="text-xs uppercase py-3 font-bold block {location.href.indexOf(
                '/admin/licenseIndex'
              ) !== -1
                ? 'text-red-500 hover:text-red-600'
                : 'text-blueGray-700 hover:text-blueGray-500'}"
            >
              <i
                class="fas fa-file mr-2 text-sm {location.href.indexOf(
                  '/admin/licenseIndex'
                ) !== -1
                  ? 'opacity-75'
                  : 'text-blueGray-300'}"></i>
              LicenseIndex
            </a>
          </li>
          {/if}

        {#if authenticated.can('download')}
        <SideBarItem caption="Downloads" href="/admin/downloadList" {location} />
          {/if}

        {#if authenticated.can('read_version')}
          <SideBarItem caption="Versions" href="/admin/versionManagement" {location} />
        {/if}

        {#if authenticated.can('read_user')}
          <SideBarItem caption="Users Management" href="/admin/usersManagement"  {location}/>
        {/if}

        {#if authenticated.can('manage_admins')}
          <SideBarItem caption="Admins Management" href="/admin/adminsManagement" {location} />
        {/if}

        {#if authenticated.can('manage_admins')}
          <SideBarItem caption="Permissions" href="/admin/permissionsManagement" {location} />
        {/if}

        {#if authenticated.can('read_computer')}
          <SideBarItem caption="Computers index" href="/admin/computerList" {location} />
        {/if}

        {#if authenticated.can('manage_mexbomber')}
          <SideBarItem caption="Suppositories" href="/admin/suppositories" {location} />
        {/if}

        {#if authenticated.can('read_stats')}
          <SideBarItem caption="Demos" href="/admin/demoes" {location} />
          {/if}

        {#if authenticated.can('read_stats')}
          <SideBarItem caption="Expiring licenses" href="/admin/expiringLicense" {location} />
        {/if}

      </ul>
    </div>
  </div>
</nav>

<style>
  :global(.svelte-tags-input-matchs-parent){
    width: 100%;
  }
</style>
