<script>
  import CardStats from "../Cards/CardStats.svelte";
  import Api from "../../services/Api";
  let loadData;
  let allComputers,
    notAssociatedComputers,
    associatedComputers,
    inActiveComputers,
    activeUsers,
    assignedLicenses,
    activeLicenses,
    inActiveLicenses;
  const bg = "/assets/img/back1.jpg";

  loadData = async () => {
    await Api.get(`admin/dashboard/stats`).then((result) => {
      if (!!result) {
        let stats = result.data;
        allComputers = stats.allComputers;
        associatedComputers = stats.associatedComputers;
        notAssociatedComputers = stats.notAssociatedComputers;
        inActiveComputers = stats.inActiveComputers;
        activeUsers = stats.activeUsers;
        assignedLicenses = stats.assignedLicenses;
        activeLicenses = stats.activeLicenses;
        inActiveLicenses = stats.inActiveLicenses;
      }
    });
  }
  loadData();
</script>

<!-- Header -->
<div class="relative md:pt-32 pb-32 pt-12" style="
background-image: url({bg});
">
  <div class="px-4 md:px-10 mx-auto w-full">
    <div>
      <!-- Card stats -->
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="allComputers"
            statTitle={allComputers}
            statDescripiron=""
            statIconName="fa fa-desktop"
            statIconColor="bg-pink-500" />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="associatedPcs"
            statTitle={associatedComputers}
            statDescripiron=""
            statIconName="fa fa-desktop"
            statIconColor="bg-orange-500" />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="notAssociatedPcs"
            statTitle={notAssociatedComputers}
            statDescripiron=""
            statIconName="fa fa-desktop"
            statIconColor="bg-pink-500" />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="inActiveComputers"
            statTitle={inActiveComputers}
            statDescripiron=""
            statIconName="fa fa-desktop"
            statIconColor="bg-emerald-500" />
        </div>
      </div>
    </div>
  </div>

  <div class="px-4 md:px-10 mx-auto w-full" style="margin-top:20px">
    <div>
      <!-- Card stats -->
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="activeUsers"
            statTitle={activeUsers}
            statDescripiron=""
            statIconName="fa fa-users"
            statIconColor="bg-lightBlue-500" />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="assignedLicenses"
            statTitle={assignedLicenses}
            statDescripiron=""
            statIconName="fa fa-file"
            statIconColor="bg-purple-500" />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="activeLicenses"
            statTitle={activeLicenses}
            statDescripiron=""
            statIconName="fa fa-file"
            statIconColor="bg-pink-500" />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="inActiveLicenses"
            statTitle={inActiveLicenses}
            statDescripiron=""
            statIconName="fa fa-file"
            statIconColor="bg-red-500" />
        </div>
      </div>
    </div>
  </div>
</div>
