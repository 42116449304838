<script>
  // library for creating dropdown menu appear on click
  import { createPopper } from "@popperjs/core";
  import { link, navigate } from "svelte-routing";
  import API from "../../services/Api";

  // core components

  const image = "../assets/img/team-1-800x800.jpg";

  let dropdownPopoverShow = false;

  let btnDropdownRef;
  let popoverDropdownRef;

  const toggleDropdown = (event) => {
    event.preventDefault();
    if (dropdownPopoverShow) {
      dropdownPopoverShow = false;
    } else {
      dropdownPopoverShow = true;
      createPopper(btnDropdownRef, popoverDropdownRef, {
        placement: "bottom-start",
      });
    }
  };

  const logOut= (e)=>{

    API.put('auth/admin/minJwtIat' , {
      date: new Date(),
    }).catch(e => {
      window.localStorage.removeItem("admin");
      window.localStorage.removeItem("token");

      navigate("/auth/login");
    }).then(() => {
      window.localStorage.removeItem("admin");
      window.localStorage.removeItem("token");

      navigate("/auth/login");
    });

  };


</script>

<div>
  <a
    class="text-blueGray-500 block"
    href="#pablo"
    bind:this={btnDropdownRef}
    on:click={toggleDropdown}>
    <div class="items-center flex">
      <span
        class="w-9 h-9 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
        <!-- <img
          alt="..."
          class="w-full rounded-full align-middle border-none shadow-lg"
          src="{image}"
        /> -->
        <li class="nav-item">
          <a
            class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
            href="">
            <i class="fas fa-user text-lg leading-lg text-white opacity-75" />
          </a>
        </li>
      </span>
    </div>
  </a>
  <div
    bind:this={popoverDropdownRef}
    class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 {dropdownPopoverShow ? 'block' : 'hidden'}">
    <a
      use:link
      href="/admin/profile"
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
      Profile
    </a>



    <div class="h-0 my-2 border border-solid border-blueGray-100" />
    <a
      href="#pablo"
      on:click={logOut}
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700">
     LogOut
    </a>
  </div>
</div>
