<script>
    import { DataTable } from "carbon-components-svelte";
    import "carbon-components-svelte/css/g10.css";
    import Dialog, { Title, Content, Actions } from "@smui/dialog";
    import Button, { Label } from "@smui/button";

    import IconButton from "@smui/icon-button";

    import * as yup from "yup";
    import { Form, Message, isInvalid } from "svelte-yup";
    import Chip, { Set, Text } from "@smui/chips";
    import Switch from "../../components/Switch.svelte";
    import Select from "svelte-select";
    import Api from "../../services/Api";
    import { element } from "svelte/internal";

    //for validations
    let schema;
    let password;

    let fields = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        //selectedRoles: [],
    };
    let submitted = false;
    let isValid;
    function formSubmit() {
        if (updatedId) {
           
            schema = yup.object().shape({
                firstName: yup.string().required().label("firstName"),
                lastName: yup.string().required().label("lastName"),
                email: yup.string().email().required().label("email"),
                //password: yup.string().required().min(8).label("password"),
                //selectedRoles: yup.array().required().label("selectedRoles"),
            });
        } else {
            schema = yup.object().shape({
                firstName: yup.string().required().label("firstName"),
                lastName: yup.string().required().label("lastName"),
                email: yup.string().email().required().label("email"),
                password: yup.string().required().min(8).label("password"),
                //selectedRoles: yup.array().required().label("selectedRoles"),
            });
        }

        submitted = true;
        isValid = schema.isValidSync(fields);
       
        if (isValid) {
            if (updatedId) {
                updateAdmin(updatedId);
            } else {
                addAdmin();
            }
        }
    }

    let addAdmin;
    let roleList;

    let isActive, note;
    let roleIds = [];

    let updatedId;
    let removeAdmin;
    let updateAdmin;
    let rowsPerPage = 10;
    let currentPage = 1;
    let total;
    let items = [];
    let loadData;
    let getRolesList;
    let open = false;
    $: selectedRoles = [];

    roleIds = [];
    let adminId;

    let showModal = false;

    function toggleModal() {
        showModal = !showModal;
    }

    $: start = (currentPage - 1) * rowsPerPage;
    $: end = Math.min(start + rowsPerPage, total);
    $: lastPage = Math.max(Math.ceil(total / rowsPerPage), 0);

    $: if (currentPage > lastPage) {
        currentPage = lastPage;
    }

    //server side data

    getRolesList = async () => {
        await Api.get("admin/roles").then((result) => {
            if (!!result) {
                let roles = result.data;
                roleList = [];
                roles.forEach((element) => {
                    let obj = { value: element.id, label: element.title };
                    roleList.push(obj);
                });
            }
        });
    };






    function handleSelect(event) {
      
        selectedRoles = [];
        

        if (!!event.detail && event.detail.length > 0) {
           // selectedRoles = [];

            event.detail.forEach((element) => {
             
                selectedRoles.push(element.value);
            });
        }

        // .. do something here
    }

    loadData = async () => {
        await Api.get("admin/admins").then((result) => {
            if (!!result) {
                items = result.data;
            }
        });
    };

    //create admin

    addAdmin = async () => {


        await Api.post("admin/admins", {
            email: fields.email,
            firstName: fields.firstName,
            lastName: fields.lastName,
            password: fields.password,
            note,
            roles: selectedRoles,
            isActive,
        }).then((result) => {
            if (!!result) {
                loadData();
                toggleModal();
            }
        });
    };

    //remove admin

    removeAdmin = async (id) => {
        await Api.delete(`admin/admins/${id}`).then((result) => {
            if (!!result) {
                loadData();
                showModal = false;
            }
        });
    };

    //update admin

    updateAdmin = async (adminId) => {
        let data;
        let upRoles=[];
        if(selectedRoles && selectedRoles.length>0){
            selectedRoles.forEach(element => {
                upRoles.push(element)
                
            });
        }
        
        if (password === ""){
            data = {
                email: fields.email,
                firstName: fields.firstName,
                lastName: fields.lastName,
                //password: password,
                note,
                roles: upRoles,
                isActive,
            };

        }else{
            data = {
                email: fields.email,
                firstName: fields.firstName,
                lastName: fields.lastName,
                password: password,
                note,
                roles: upRoles,
                isActive,
            };

        }
          

        await Api.put(`admin/admins/${adminId}`, data
          
        ).then((result) => {
            if (!!result) {
                loadData();
                showModal = false;
            }
        });
    };

    loadData();
    getRolesList();
</script>

<style>
    .invalid {
        border-color: red !important;
    }
</style>

{#if showModal}
    <div
        style="margin:auto;top:50px;z-index:20000000"
        class=" w-full px-4 md:w-8/12 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center ">
        <div class="relative w-auto my-6 mx-auto max-w-sm">
            <!--content-->
            <div
                class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none modal-height">
                <!--header-->
                <div
                    class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 class="text-3xl font-semibold">Admin</h3>
                    <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        on:click={() => (showModal = false)}>
                        <span
                            class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                        </span>
                    </button>
                </div>
                <!--body-->
                <Form
                    class="form"
                    {schema}
                    {fields}
                    submitHandler={formSubmit}
                    {submitted}
                    color="#b00020">
                    <div class="container px-4 mx-auto">
                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="firstName">
                                        firstName
                                    </label>
                                    <input
                                        bind:value={fields.firstName}
                                        id="name"
                                        type="text"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="firstName" />
                                    <Message name="firstName" />
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="lastName">
                                            lastName
                                        </label>
                                        <input
                                            bind:value={fields.lastName}
                                            id="name"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="lastName" />
                                        <Message name="lastName" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                {#if updatedId}
                                    <div class="relative w-full">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="password">
                                            password
                                        </label>

                                        <input
                                            bind:value={password}
                                            id="password"
                                            type="password"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="password" />
                                    </div>
                                {:else}
                                    <div class="relative w-full">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="password">
                                            password 
                                        </label>

                                        <input
                                            bind:value={fields.password}
                                            id="password"
                                            type="password"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="password" />
                                        <Message name="password" />
                                    </div>
                                {/if}
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="email">
                                            email
                                        </label>
                                        <input
                                            bind:value={fields.email}
                                            id="name"
                                            type="email"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="email" />
                                        <Message name="email" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="note">
                                        Roles
                                    </label>

                                    <Select
                                        items={roleList}
                                        value={selectedRoles}
                                        isMulti={true}
                                        on:select={handleSelect}
                                    />


                                </div>
                            </div>

                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="note">
                                        note
                                    </label>
                                    <textarea
                                        bind:value={note}
                                        id="note"
                                        type="text"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="note" />
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="">
                                            enabled
                                        </label>
                                        <Switch bind:checked={isActive} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--footer-->
                    <div
                        class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
                        style=" margin-top: 20px;
                                padding: 20px;">
                        <Button
                            color="secondary"
                            on:click={() => (showModal = false)}
                            variant="raised"
                            style="margin-right:10px">
                            <Label>Close</Label>
                        </Button>

                        <Button
                            color="secondary"
                            variant="raised"
                            letiant="raised">
                            <Label>Save Changes</Label>
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    </div>

    <div class="opacity-25 fixed inset-0 z-40 bg-black  overlay" />
{/if}
<!--dialog-->
<Dialog
    bind:open
    aria-labelledby="simple-title"
    aria-describedby="simple-content">
    <!-- Title cannot contain leading whitespace due to mdc-typography-baseline-top() -->
    <Title id="simple-title">Remove Admin</Title>
    <Content id="simple-content">Are You Sure?</Content>
    <Actions>
        <Button>
            <Label>No</Label>
        </Button>
        <Button
            on:click={() => {
               
                removeAdmin(adminId);
            }}>
            <Label>Yes</Label>
        </Button>
    </Actions>
</Dialog>

<div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4" style="padding-top:5rem;">
        <button
            on:click={() => {
                fields = { firstName: '', lastName: '', email: '', password: '' };
                selectedRoles: [],
                selectedRoles = [];

                toggleModal();
            }}
            style="margin:20px"
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button">
            <i class="fas fa-plus" />
            Admin
        </button>
        <DataTable
            zebra
            title="Admins List"
            size="short"
            expandable
            headers={[{ key: 'firstName', value: 'firstName' }, { key: 'lastName', value: 'lastName' }, { key: 'email', value: 'email' }, { key: 'isActive', value: 'isActive' }, { key: 'actions', value: 'Actions' }]}
            rows={items}>
            <span slot="cell" let:cell let:row>
                {#if cell.key === 'actions'}
                    <IconButton
                        action=""
                        title=""
                        on:click={() => {
                            open = true;
                            adminId = row.id;
                        }}>
                        <i
                            style="color:red;font-size:14px"
                            class="fa fa-trash"
                            aria-hidden="true" />
                    </IconButton>

                    <IconButton
                        class="fa fa-pen"
                        on:click={() => {
                           
                            updatedId = row.id;
                            fields.firstName = row.firstName;
                            fields.lastName = row.lastName;
                            fields.email = row.email;
                            isActive = row.isActive;
                            note = row.note;

                            if (row.roles && row.roles.length > 0) {
                              
                                selectedRoles = [];
                                row.roles.forEach((element) => {
                                    let obj = { value: element.id, label: element.title };
                                    selectedRoles.push(obj);
                                });
                            }

                            toggleModal();
                        }}>
                        <i
                            style="color:green;font-size:14px"
                            class="fa fa-pen"
                            aria-hidden="true" />
                    </IconButton>
                {:else}{cell.value}{/if}
            </span>
            <div slot="expanded-row" let:row>
                <span style="color:red">permissions:</span>
                <Set chips={row.permissions} let:chip>
                    <!-- Note: the `chip` property is required! -->
                    <Chip {chip} shouldRemoveOnTrailingIconClick={false}>
                        <Text tabindex="0">{chip.title}</Text>
                    </Chip>
                </Set>
            </div>
        </DataTable>
    </div>
</div>
