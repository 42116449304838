<script>
    import DataTable, {
        Head,
        Body,
        Row,
        Cell,
        Pagination,
    } from "@smui/data-table";

    import IconButton from "@smui/icon-button";

    import Api from "../../services/Api";
    import Select from "svelte-select";
    import Button, {Label} from "@smui/button";
    import {CUSTOMER} from "../../stores/store.js";
    import SelectCustomerAlert from "../../components/SelectCustomerAlert.svelte";


    let rowsPerPage = 10;
    let currentPage = 1;
    let items = [];
    let loadData;
    let customerId;


    let total;


    $: start = (currentPage - 1) * rowsPerPage;
    $: end = Math.min(start + rowsPerPage, total);
    $: lastPage = Math.max(Math.ceil(total / rowsPerPage), 0);

    $: if (currentPage > lastPage) {
        currentPage = lastPage;
    }

    $: {
        CUSTOMER.subscribe((value) => {
            if (value) {
                customerId = value.id;

                loadData(1, rowsPerPage, customerId);
            }
        });
    }


    //server side data

    loadData = async (page, limit, customerId) => {


        await Api.get(`admin/users/${customerId}/logs?page=${page}&limit=${limit}`).then((result) => {
            if (!!result) {
                total = result.totalCount;
                items = result.data;
            }
        });
    };


    let columns = [
        "pc",
        "ip",
        "swType",
        "note",
        "created at"

    ];
</script>

{#if customerId}

<div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4" style="padding-top:5rem;">
        <!-- <div style="padding:10px">


        </div> -->
        <DataTable table$aria-label="Todo list" style="width: 100%;">
            <Head>
                <Row>
                    {#each columns as column}
                        <Cell>{column}</Cell>
                    {/each}
                </Row>
            </Head>
            <Body>
                {#each items as item}
                    <Row>
                        <Cell>{item.pc}</Cell>
                        <Cell>{item.ip}</Cell>
                        <Cell>{item.swType}</Cell>
                        <Cell>{item.note}</Cell>
                        <Cell>{new Date(item.createdAt).toLocaleString() }</Cell>
                    </Row>
                {/each}
            </Body>

            <Pagination slot="paginate">
                <!-- <div slot="rowsPerPage">
                        <Label>Rows Per Page</Label>

                            <Select variant="outlined" bind:value={rowsPerPage} noLabel  on:change={() => loadData(currentPage,rowsPerPage) }>
                                <Option value={10}>10</Option>
                                <Option value={25}>25</Option>
                                <Option value={100}>100</Option>
                              </Select>
                    </div> -->
                <div slot="total">{start}-{end} of {total}</div>

                <IconButton
                    class="material-icons"
                    action="first-page"
                    title="First page"
                    on:click={() => (currentPage = 1)}
                    disabled={currentPage === 1}>
                    first_page
                </IconButton>
                <IconButton
                    class="material-icons"
                    action="prev-page"
                    title="Prev page"
                    on:click={() => {
                        currentPage = currentPage - 1;
                        loadData(currentPage, rowsPerPage,customerId);
                    }}
                    disabled={currentPage === 1}>
                    chevron_left
                </IconButton>
                <IconButton
                    class="material-icons"
                    action="next-page"
                    title="Next page"
                    on:click={() => {
                        currentPage = currentPage + 1;
                        loadData(currentPage, rowsPerPage,customerId);
                    }}
                    disabled={currentPage === lastPage}>
                    chevron_right
                </IconButton>
                <IconButton
                    class="material-icons"
                    action="last-page"
                    title="Last page"
                    on:click={() => (currentPage = lastPage)}
                    disabled={currentPage === lastPage}>
                    last_page
                </IconButton>
            </Pagination>
        </DataTable>
    </div>
</div>
{:else}
    <SelectCustomerAlert />
{/if}

