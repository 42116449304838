<script>
  // core components
  import CardStats from "../Cards/CardStats.svelte";
  const bg = "/assets/img/back1.jpg";
</script>

<!-- Header -->
<div class="relative bg-red-500 md:pt-32 pb-32 pt-12"
style="
background-image: url({bg});
"

>
  <div class="px-4 md:px-10 mx-auto w-full">
    <div>
      <!-- Card stats -->
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="SHOPS"
            statTitle="10"
            statDescripiron=""
            statIconName="fa fa-university"
            statIconColor="bg-red-500"
          
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="Associated PCS"
            statTitle="20"
            statDescripiron=""
            statIconName="fa fa-desktop"
            statIconColor="bg-orange-500"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="Server Licences"
            statTitle="9"
            statDescripiron=""
            statIconName="fa fa-file"
            statIconColor="bg-pink-500"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="Client Licences"
            statTitle="5"
            statDescripiron=""
            statIconName="fa fa-file"
            
            statIconColor="bg-emerald-500"
          />
        </div>
      </div>
    </div>
  </div>
</div>
