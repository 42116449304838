<!-- App.svelte -->
<script>
  import { Router, Route } from "svelte-routing";

  // Admin Layout
  import Admin from "./layouts/Admin.svelte";
  // Auth Layout
  import Auth from "./layouts/Auth.svelte";


  // No Layout Pages
  import Index from "./views/Index.svelte";
  import Landing from "./views/Landing.svelte";


  import Notifications from "svelte-notify";


  export let url = "";

  
</script>
<Notifications /> 
<Router {url}>
  <!-- admin layout -->
  <Route path="admin/*admin" component={Admin} />

  <!-- auth layout -->
  <Route path="auth/*auth" component={Auth} />
  <!-- no layout pages -->
  <Route path="landing" component={Landing} />

  <!-- <Route path="/" component="{Index}" /> -->
  <Route path="/" component={Index} />

</Router>
