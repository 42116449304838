<script>
    import DataTable, {
        Head,
        Body,
        Row,
        Cell,
        Pagination,
    } from "@smui/data-table";

    import IconButton from "@smui/icon-button";
    import Select, { Option } from "@smui/select";

    import Api from "../../services/Api";

    import Button, { Label } from "@smui/button";

    let rowsPerPage = 10;
    let currentPage = 1;
    let items = [];
    let loadData;
    let customerId;

    let total;

    $: start = currentPage * rowsPerPage;
    $: end = Math.min(start + rowsPerPage, items.length);
    $: slice = items.slice(start, end);
    $: lastPage = Math.max(Math.ceil(items.length / rowsPerPage) - 1, 0);
    $: if (currentPage > lastPage) {
        currentPage = lastPage;
    }

    //server side data

    loadData = async () => {
        await Api.get("admin/dashboard/demos").then((result) => {
            if (!!result) {
                // total = result.totalCount;
                items = result.data;
                total = items.length;
                console.log("demoessss", items);
            }
        });
    };

    let columns = ["client", "pc", "type", "status", "date"];
    loadData();
</script>

<div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4" style="padding-top:5rem;">
        <Pagination slot="paginate">
            <div slot="rowsPerPage">
                <Label>Rows Per Page</Label>

                <Select variant="outlined" bind:value={rowsPerPage} on:blue={() => loadData(currentPage, rowsPerPage)} noLabel>
                    <Option value={10}>10</Option>
                    <Option value={25}>25</Option>
                    <Option value={100}>100</Option>
                </Select>
            </div>
            <div slot="total">{start + 1}-{end} of {total}</div>

            <IconButton
                    class="material-icons"
                    action="first-page"
                    title="First page"
                    on:click={() => (currentPage = 0)}
                    disabled={currentPage === 0}>first_page</IconButton
            >
            <IconButton
                    class="material-icons"
                    action="prev-page"
                    title="Prev page"
                    on:click={() => currentPage--}
                    disabled={currentPage === 0}>chevron_left</IconButton
            >
            <IconButton
                    class="material-icons"
                    action="next-page"
                    title="Next page"
                    on:click={() => currentPage++}
                    disabled={currentPage === lastPage}
            >chevron_right</IconButton
            >
            <IconButton
                    class="material-icons"
                    action="last-page"
                    title="Last page"
                    on:click={() => (currentPage = lastPage)}
                    disabled={currentPage === lastPage}>last_page</IconButton
            >
        </Pagination>

        <DataTable style="width: 100%;">
            <Head>

                <Row>
                  <br/>
                </Row>
                <Row>
                    {#each columns as column}
                        <Cell>{column}</Cell>
                    {/each}
                </Row>
            </Head>
            <Body>
                {#each slice as item}
                    <Row>
                        <Cell>{item.client}</Cell>
                        <Cell>{item.pc}</Cell>
                        <Cell>{item.type}</Cell>
                        <Cell>{item.status}</Cell>
                        <Cell>{item.date}</Cell>
                    </Row>
                {/each}
            </Body>

        </DataTable>
    </div>
</div>
