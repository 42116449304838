// Api.js
import axios from "axios";
import { notify } from "svelte-notify";
import {navigate} from 'svelte-navigator';

// Create a instance of axios to use the same base url.
const axiosAPI = axios.create({
    baseURL: "_envBaseUrl" // injected by rollup
});

// implement a method to execute all the request from here.
const apiRequest = (method, url, request, responseType) => {

    let headers;
    if (url != "auth/admin/jwt") {
        var token = localStorage.getItem("token");
        headers = {
            authorization: `Bearer ${token}`
        }
    } else {
        headers = {};
    }


    //using the axios instance to perform the request that received from each http method
    return axiosAPI({
            method,
            url,
            data: request,
            headers,
            responseType
        }).then(res => {
            return Promise.resolve(res.data);
        })
        .catch(err => {

            // return Promise.reject(err);
            if (err.response.status == 500) {
                notify({
                    title: "status:500",
                    message: "server side error",
                    timeout: 2000,
                    type: "danger"

                });
            } else if (err.response.status == 401) {

                notify({
                    title: "Error 401",
                    message: "Authentication failed, please login again",
                    timeout: 2000,
                    type: "danger"
                });

                navigate("/auth/login", {replace: true});
                window.location.href = "/auth/login";

            } else if (err.response.status == 422) {
                notify({
                    title: "Error 422",
                    message: "input parameters are wrong try again...",
                    timeout: 2000,
                    type: "danger"

                });
            }
            else if (err.response.status == 403) {
                notify({
                    title: "Not authorised",
                    message: "You're not authorised to perform this action",
                    timeout: 2000,
                    type: "danger"

                });
            }
            else {
                // Something happened in setting up the request that triggered an Error
                notify({
                    title: err.response.status,
                    message: console.error.message,
                    timeout: 1000,
                    type: "danger"

                });
            }
        });
};

// function to execute the http get request
const get = (url, request, responseType) => apiRequest("get", url, request, responseType);

// function to execute the http delete request
const deleteRequest = (url, request) => apiRequest("delete", url, request);

// function to execute the http post request
const post = (url, request) => apiRequest("post", url, request);

// function to execute the http put request
const put = (url, request) => apiRequest("put", url, request);

// function to execute the http path request
const patch = (url, request) => apiRequest("patch", url, request);

// expose your method to other services or actions
const API = {
    get,
    delete: deleteRequest,
    post,
    put,
    patch
};
export default API;
