<script>
    import DataTable, {
        Head,
        Body,
        Row,
        Cell,
        Pagination,
    } from "@smui/data-table";
    import Dialog, { Title, Content, Actions } from "@smui/dialog";
    import Button, { Label } from "@smui/button";

    import IconButton from "@smui/icon-button";

    import Switch from "../../components/Switch.svelte";
    import Radio from "@smui/radio";
    import FormField from "@smui/form-field";
    import * as yup from "yup";
    import { Form, Message, isInvalid } from "svelte-yup";
    import Api from "../../services/Api";
    import Select, {Option} from "@smui/select";
    import LinearProgress from "@smui/linear-progress";
    import {notify} from "svelte-notify";

    //validations
    let schema = yup.object().shape({
        firstName: yup.string().required().max(30).label("firstName"),
        lastName: yup.string().required().max(30).label("lastName"),
        email: yup.string().email().required().label("email"),
        pi: yup.string().optional().max(13).label("pi"),
        cf: yup.string().optional().max(16).label("cf"),
        cap: yup.string().optional().max(5).label("cap"),
        province: yup.string().optional().max(5).label("province"),
        address: yup.string().optional().max(80).label("address"),
    });

    let fields = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        pi: "",
        cf: "",
        cap: "",
        address: "",
    };
    let submitted = false;
    let isValid;
    function formSubmit() {
        submitted = true;
        isValid = schema.isValidSync(fields);
        if (isValid) {
            if (updatedId) {
                updateUser(updatedId);
            }
        }
    }

    let filters = "All";

    let name,
        email,
        companyName,
        phone,
        address,
        cap,
        province,
        pi,
        cf,
        alternate_email,
        enabled,
        note,
        firstName,
        lastName;

    let updatedId;
    let removeUser;
    let updateUser;
    let rowsPerPage = 10;
    let currentPage = 1;
    let total;
    let items = [];
    let loadData;
    let link = "";
    let dialogIsOpen;

    let userId;

    let columns = [
        "name",
        "email",
        "companyName",
        "phone",
        "address",
        "cap",
        "province",
        "pi",
        "cf",
        "alternate_email",
        "enabled",
        "note",
        "actions",
    ];

    let showModal = false;

    function toggleModal() {
        showModal = !showModal;
    }

    $: start = (currentPage - 1) * rowsPerPage;
    $: end = Math.min(start + rowsPerPage, total);
    $: lastPage = Math.max(Math.ceil(total / rowsPerPage), 0);

    $: if (currentPage > lastPage) {
        currentPage = lastPage;
    }

    //server side data
    let loaded = false;
    loadData = async (page, limit) => {
        loaded = false;
        if (filters == "All") {
            link = `admin/users?page=${page}&limit=${limit}`;
        } else if (filters == "noLicense") {
            link = `admin/users?page=${page}&limit=${limit}&noLicense=true`;
        } else if (filters == "noPC") {
            link = `admin/users?page=${page}&limit=${limit}&noPC=true`;
        } else {
            link = `admin/users?page=${page}&limit=${limit}`;
        }

        await Api.get(link).then((result) => {
            if (!!result) {
                total = result.totalCount;
                items = result.data;
                loaded = true;
            }
        });
    };

    //remove user
    removeUser = async (userId) => {
        await Api.put(`admin/users/${userId}`, { email, enabled }).then(
            (result) => {
                if (!!result) {
                    loadData(currentPage, rowsPerPage);
                }
            }
        );
    };

    //update shop
    updateUser = async (userId) => {
        await Api.put(`admin/users/${userId}`, {
            firstName: fields.firstName,
            lastName: fields.lastName,
            password: fields.password ?? undefined,
            email: fields.email,
            companyName: fields.companyName,
            phone: fields.phone,
            address: fields.address,
            cap: fields.cap,
            province: fields.province,
            pi: fields.pi,
            cf: fields.cf,
            alternate_email: fields.alternate_email,
            note: fields.note,
            enabled: fields.enabled,
        }).then((result) => {
            if (!!result) {
                loadData(currentPage, rowsPerPage);
                showModal = false;
                notify({
                    title: "User updated",
                    message: "User updated",
                    timeout: 1500,
                    type: "success"
                });
            }
        });
    };

    loadData(1, rowsPerPage);
</script>

{#if showModal}
    <div
        style="margin:auto;top:50px"
        class=" w-full px-4 md:w-8/12 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center "
    >
        <div class="relative w-auto my-6 mx-auto max-w-sm">
            <!--content-->
            <div
                class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none modal-height"
            >
                <!--header-->
                <div
                    class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t"
                >
                    <h3 class="text-3xl font-semibold">User</h3>
                    <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        on:click={() => (showModal = false)}
                    >
                        <span
                            class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                        >
                            ×
                        </span>
                    </button>
                </div>
                <!--body-->
                <Form
                        class="form"
                        {schema}
                        {fields}
                        submitHandler={formSubmit}
                        {submitted}
                        color="#b00020"
                >
                    <div class="container px-4 mx-auto">
                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="firstName"
                                    >
                                        firstName
                                    </label>
                                    <input
                                            bind:value={fields.firstName}
                                            id="firstName"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="firstName"
                                    />
                                    <Message name="firstName"/>
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                for="lastName"
                                        >
                                            lastName
                                        </label>
                                        <input
                                                bind:value={fields.lastName}
                                                id="lastName"
                                                type="text"
                                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="lastName"
                                        />
                                        <Message name="lastName"/>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="flex flex-wrap my-2">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="firstName"
                                    >
                                        Password
                                    </label>
                                    <input
                                            bind:value={fields.password}
                                            id="password"
                                            type="password"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Password"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="companyName"
                                    >
                                        companyName
                                    </label>
                                    <input
                                            bind:value={fields.companyName}
                                            id="companyName"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="companyName"
                                    />
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                for="email"
                                        >
                                            email
                                        </label>
                                        <input
                                                bind:value={fields.email}
                                                id="email"
                                                type="email"
                                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="email"
                                        />
                                        <Message name="email"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="phone"
                                    >
                                        phone
                                    </label>
                                    <input
                                            bind:value={fields.phone}
                                            id="phone"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="phone"
                                    />
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                for="address"
                                        >
                                            address
                                        </label>
                                        <input
                                                bind:value={fields.address}
                                                id="address"
                                                type="text"
                                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="address"
                                        />
                                        <Message name="address"/>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="cap"
                                    >
                                        cap
                                    </label>
                                    <input
                                            bind:value={fields.cap}
                                            id="cap"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="cap"
                                    />
                                    <Message name="cap"/>
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                for="province"
                                        >
                                            province
                                        </label>
                                        <input
                                                bind:value={fields.province}
                                                id="province"
                                                type="text"
                                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="province"
                                        />
                                        <Message name="province"/>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="pi"
                                    >
                                        pi
                                    </label>
                                    <input
                                            bind:value={fields.pi}
                                            id="pi"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="pi"
                                    />
                                    <Message name="pi"/>
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                for="cf"
                                        >
                                            cf
                                        </label>
                                        <input
                                                bind:value={fields.cf}
                                                id="cf"
                                                type="text"
                                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="cf"
                                        />
                                        <Message name="cf"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="alternate_email"
                                    >
                                        alternate_email
                                    </label>
                                    <input
                                            bind:value={fields.alternate_email}
                                            id="alternate_email"
                                            type="email"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="alternate_email"
                                    />
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        >
                                            enabled
                                        </label>
                                        <Switch bind:checked={fields.enabled}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="note"
                                    >
                                        note
                                    </label>
                                    <textarea
                                            bind:value={fields.note}
                                            id="note"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="note"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
                         style=" margin-top: 20px;
                            padding: 20px;">
                        <Button
                            color="secondary"
                            on:click={() => (showModal = false)}
                            variant="raised"
                            style="margin-right:10px"
                        >
                            <Label>Close</Label>
                        </Button>

                        <Button color="secondary" variant="raised" letiant="raised">
                            <Label>Save Changes</Label>
                        </Button>
                    </div>
                </Form>


                <!--footer-->
            </div>
        </div>
    </div>

    <div class="opacity-25 fixed inset-0 z-40 bg-black overlay"></div>
{/if}
<!--dialog-->
<Dialog
        bind:dialogIsOpen
    aria-labelledby="simple-title"
    aria-describedby="simple-content"
>
    <!-- Title cannot contain leading whitespace due to mdc-typography-baseline-top() -->
    <Title id="simple-title">Remove User</Title>
    <Content id="simple-content">Are You Sure?</Content>
    <Actions>
        <Button>
            <Label>No</Label>
        </Button>
        <Button
            on:click={() => {
                console.log("userId is..", userId);
                console.log("email is..", email);
                enabled = false;

                removeUser(userId);

                showModal = false;
                loadData(1, rowsPerPage);
            }}
        >
            <Label>Yes</Label>
        </Button>
    </Actions>
</Dialog>

<div class="flex flex-wrap">
    <div class="w-full mb-12 px-4" style="padding-top:6rem;">

        <Pagination>
            <div slot="rowsPerPage">

                <Label>Rows Per Page</Label>
                <Select variant="outlined" bind:value={rowsPerPage} on:blur={() => {loadData(currentPage, rowsPerPage)}}>
                    <Option value={10}>10</Option>
                    <Option value={25}>25</Option>
                    <Option value={50}>50</Option>
                </Select>

            </div>

            <div slot="total">{start}-{end} of {total}</div>

            <IconButton
                    class="material-icons"
                    action="first-page"
                    title="First page"
                    on:click={() => (currentPage = 1)}
                    disabled={currentPage === 1}
            >
                first_page
            </IconButton>
            <IconButton
                    class="material-icons"
                    action="prev-page"
                    title="Prev page"
                    on:click={() => {
                        currentPage = currentPage - 1;
                        loadData(currentPage, rowsPerPage);
                    }}
                    disabled={currentPage === 1}
            >
                chevron_left
            </IconButton>
            <IconButton
                    class="material-icons"
                    action="next-page"
                    title="Next page"
                    on:click={() => {
                        currentPage = currentPage + 1;
                        loadData(currentPage, rowsPerPage);
                    }}
                    disabled={currentPage === lastPage}
            >
                chevron_right
            </IconButton>
            <IconButton
                    class="material-icons"
                    action="last-page"
                    title="Last page"
                    on:click={() => (currentPage = lastPage)}
                    disabled={currentPage === lastPage}
            >
                last_page
            </IconButton>
        </Pagination>

        <DataTable style="width: 100%;">
            <Head>
                <Row>
                    <div class="radio-demo">
                        {#each ["All", "noLicense", "noPC"] as option}
                            <FormField>
                                <Radio
                                    on:change={loadData(
                                        currentPage,
                                        rowsPerPage
                                    )}
                                    bind:group={filters}
                                    value={option}
                                    touch
                                />
                                <span slot="label">{option}</span>
                            </FormField>
                        {/each}
                    </div>
                </Row>

                <!-- <Row>
            <button
                on:click={() => {
                    fields = { firstName: '', lastName: '', email: '', pi: '', cf: '', cap: '' };
                    companyName = '';
                    address = '';
                    phone = '';
                    alternate_email = '';
                    note = '';
                    province = '';

                    toggleModal();
                }}
                style="margin:20px"
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button">
                <i class="fas fa-plus" />
                User
            </button>
        </Row> -->

                <Row>
                    {#each columns as column}
                        <Cell>{column}</Cell>
                    {/each}
                </Row>
            </Head>
            <Body>
                {#each items as item}
                    <Row>
                        <Cell>{item.firstName + " " + item.lastName}</Cell>

                        <Cell>{item.email}</Cell>
                        <Cell>{item.companyName}</Cell>
                        <Cell>{item.phone}</Cell>
                        <Cell>{item.address}</Cell>
                        <Cell>{item.cap}</Cell>
                        <Cell>{item.province}</Cell>
                        <Cell>{item.pi}</Cell>
                        <Cell>{item.cf}</Cell>
                        <Cell>{item.alternate_email ?? ''}</Cell>
                        <Cell>{item.enabled}</Cell>
                        <Cell>{item.note ?? ''}</Cell>

                        <Cell>
                            <IconButton
                                action=""
                                title=""
                                on:click={() => {
                                    dialogIsOpen = true;
                                    userId = item.id;
                                    email = item.email;
                                }}
                            >
                                <i
                                    style="color:red;font-size:14px"
                                    class="fa fa-trash"
                                    aria-hidden="true"></i>
                            </IconButton>

                            <IconButton
                                class="fa fa-pencil"
                                on:click={() => {
                                    updatedId = item.id;
                                    fields.firstName = item.firstName ?? '';
                                    fields.lastName = item.lastName ?? '';
                                    fields.email = item.email ?? '';
                                    fields.companyName = item.companyName ?? '';
                                    fields.phone = item.phone ?? '';
                                    fields.address = item.address ?? '';
                                    fields.cap = item.cap ?? '';
                                    fields.province = item.province ?? '';
                                    fields.pi = item.pi ?? '';
                                    fields.cf = item.cf ?? '';
                                    fields.alternate_email = item.alternate_email ?? '';
                                    fields.enabled = item.enabled ?? '';
                                    fields.note = item.note ?? '';

                                    toggleModal();
                                }}
                            >
                                <i
                                    style="color:green;font-size:14px"
                                    class="fa fa-pen"
                                    aria-hidden="true"></i>
                            </IconButton>
                        </Cell>
                    </Row>
                {/each}
            </Body>

            <LinearProgress
                    indeterminate
                    bind:closed={loaded}
                    aria-label="Data is being loaded..."
                    slot="progress"
            />
        </DataTable>
    </div>
</div>

<style>
    .radio-demo > :global(*) {
        margin: 0 0.2em;
    }
    :global(.bottom-right){
        z-index: 1000;
    }
</style>
