<script>
    import DataTable, {
        Head,
        Body,
        Row,
        Cell,
        Pagination,
    } from "@smui/data-table";
    import Dialog, {Title, Content, Actions} from "@smui/dialog";
    import Button, {Label} from "@smui/button";

    import IconButton from "@smui/icon-button";
    import {CUSTOMER} from "../../stores/store.js";

    import Flatpickr from "svelte-flatpickr";
    import "flatpickr/dist/flatpickr.css";
    import Radio from "@smui/radio";
    import FormField from "@smui/form-field";
    import Api from "../../services/Api";
    import SelectCustomerAlert from "../../components/SelectCustomerAlert.svelte";

    $: filters = "false";

    let customerId;

    let value, formattedValue;
    let rowsPerPage = 10;
    let currentPage = 1;
    let total;
    let items = [];
    let loadData;
    let sort = "type";
    let sortDirection = "ascending";
    let showModal = false;
    let licId;
    let version;
    let type;
    let permissions;
    let expirationDate;
    let updateLicense;
    let searchTerm = " ";

    let columns = [
        "id",
        "key",
        "inUse",
        "name",
        "clientDesc",
        "swType",
        "type",
        "purchaseDate",
        "activationDate",
        "expirationDate",
        "version",
        "permissions",
        "settings",
    ];

    const options = {
        enableTime: false,

        onChange(selectedDates, dateStr) {
            console.log("flatpickr hook", selectedDates, dateStr);
            expirationDate = dateStr;
            console.log("change ...", expirationDate);
        },
    };

    expirationDate = formattedValue;

    function handleChange(event) {
        const [selectedDates, dateStr] = event.detail;

        console.log({selectedDates, dateStr});
        expirationDate = dateStr;
    }

    function saveChanges() {
        console.log("date is ...", expirationDate);
        updateLicense(customerId, licId);
    }

    $: start = (currentPage - 1) * rowsPerPage;
    $: end = Math.min(start + rowsPerPage, total);
    $: lastPage = Math.max(Math.ceil(total / rowsPerPage), 0);

    $: if (currentPage > lastPage) {
        currentPage = lastPage;
    }

    //server side data


    let link = "";

    loadData = async (userId, page, limit) => {
        // if (filters == "false") {
        //     link = `admin/users/${userId}/licenses?page=${page}&limit=${limit}&isActive=false`;
        // } else if (filters == "true") {
        //     link = `admin/users/${userId}/licenses?page=${page}&limit=${limit}&isActive=true`;
        // }

        await Api.get(`admin/users/${userId}/licenses?page=${page}&limit=${limit}`).then((result) => {
            if (!!result) {
                items = result.data;
                total = result.totalCount;
            }
        });
    };

    function handleSort() {
        items.sort((a, b) => {
            const [aVal, bVal] = [a[sort], b[sort]][
                sortDirection === "ascending" ? "slice" : "reverse"
                ]();
            if (typeof aVal === "string") {
                return aVal.localeCompare(bVal);
            }
            return aVal - bVal;
        });

        items = items;
    }

    // $:filteredList = items.filter(item => item.name.indexOf(searchTerm) !== -1);

    function toggleModal() {
        showModal = !showModal;
    }

    //update license

    updateLicense = async (userId, licenseId) => {
        await Api.put(`admin/users/${userId}/licenses/${licenseId}`, {
            expirationDate: expirationDate === "" ? null : expirationDate,
            version,
            type,
            permissions,
        }).then((result) => {
            if (!!result) {
                loadData(customerId, 1, rowsPerPage);
                showModal = false;
            }
        });

    };

    console.log("customerid is...", customerId);
    $: if (!customerId) {
        CUSTOMER.subscribe((value) => {
            if (value) {
                customerId = value.id;
                filters = "true";

                loadData(customerId, 1, 10);
            }
        });
    }
</script>

{#if customerId}
    {#if showModal}
        <div
            style="margin:auto"
            class=" w-full px-4 md:w-8/12 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center ">
            <div class="relative w-auto my-6 mx-auto max-w-sm">
                <!--content-->
                <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none modal-height">
                    <!--header-->
                    <div
                        class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                        <h3 class="text-3xl font-semibold">Edit License</h3>
                        <button
                            class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            on:click={() => (showModal = false)}>
                            <span
                                class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                            </span>
                        </button>
                    </div>
                    <!--body-->
                    <div class="container px-4 mx-auto">
                        <div class="flex flex-wrap">
                            <div class="w-full px-4 sm:w-8/12">
                                <form>
                                    <div class="relative w-full">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="deadline">
                                            DeadLine
                                        </label>

                                        <Flatpickr
                                            {options}
                                            bind:value={expirationDate}
                                            bind:formattedValue={expirationDate}
                                            on:change={handleChange}
                                            name="date" />
                                    </div>

                                    <div class="relative w-full">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="version">
                                            Version*
                                        </label>
                                        <input
                                            bind:value={version}
                                            id="version"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Version" />
                                    </div>

                                    <div class="relative w-full">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="type">
                                            LicenseType*
                                        </label>
                                        <input
                                            bind:value={type}
                                            id="type"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="licenseType" />
                                    </div>

                                    <div class="relative w-full">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="permissions">
                                            Permissions*
                                        </label>
                                        <input
                                            bind:value={permissions}
                                            id="permissions"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="permissions" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!--footer-->
                    <div
                        class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            on:click={() => (showModal = false)}>
                            Close
                        </button>
                        <button
                            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            on:click={saveChanges}>
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="opacity-25 fixed inset-0 z-40 bg-black overlay" />
    {/if}

    <div class="flex flex-wrap">
        <div class="w-full mb-12 px-4" style="padding-top:6rem;">
    <DataTable
        sortable
        bind:sort
        bind:sortDirection
        on:MDCDataTable:sorted={handleSort}
        style="width: 100%;">
        <Head>
            <!-- <Row>
                <div class="radio-demo">
                    <span style="margin:10px"> isActive:</span>
                    {#each ['false', 'true'] as option}
                        <FormField>
                            <Radio
                                on:change={loadData(customerId, currentPage, rowsPerPage)}
                                bind:group={filters}
                                value={option}
                                touch />
                            <span slot="label">{option}</span>
                        </FormField>
                    {/each}
                </div>
            </Row> -->
            <!-- <Row>
                <div
                    class="relative flex w-full flex-wrap items-stretch mb-6"
                    style="width:400px;padding:5px">
                    <input
                        type="text"
                        placeholder="Search..."
                        class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full pr-10"
                        bind:value={searchTerm} />
                    <span
                        class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3">
                        <i class="fas fa-search" />
                    </span>
                </div>
            </Row> -->

            <Row>
                <Cell columnId="key" style="width: 100%;">
                    <Label>key</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="inUse" style="width: 100%;">
                    <Label>inUse</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="name" style="width: 100%;">
                    <Label>Name</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="clientDesc" style="width: 100%;">
                    <Label>clientDesc</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>

                <Cell columnId="swType" style="width: 100%;">
                    <Label>swType</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="type" style="width: 100%;">
                    <Label>type</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="purchaseDate" style="width: 100%;">
                    <Label>purchaseDate</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="activationDate" style="width: 100%;">
                    <Label>activationDate</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="expirationDate" style="width: 100%;">
                    <Label>expirationDate</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="version" style="width: 100%;">
                    <Label>version</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>
                <Cell columnId="permissions" style="width: 100%;">
                    <Label>permissions</Label>
                    <!-- For non-numeric columns, icon comes second. -->
                    <IconButton class="material-icons">arrow_upward</IconButton>
                </Cell>

                <Cell>
                    Actions
                    <!-- For non-numeric columns, icon comes second. -->
                </Cell>
                <!-- {#each columns as column}


                <Cell columnId="column" l>
                <Label>{column}</Label>
                    <IconButton class="material-icons">arrow_upward</IconButton>
                  </Cell>

            {/each} -->
            </Row>
        </Head>
        <Body>
            {#each items as item (item.id)}
                <Row>
                    <Cell>{item.key}</Cell>
                    <Cell>{item.inUse}</Cell>
                    <Cell>{item.name}</Cell>
                    <Cell>{item.clientDesc}</Cell>
                    <Cell>{item.swType}</Cell>
                    <Cell>{item.type}</Cell>

                    <Cell>{item.purchaseDate}</Cell>
                    <Cell>{item.activationDate}</Cell>
                    <Cell>{item.expirationDate}</Cell>
                    <Cell>{item.version}</Cell>
                    <Cell>{item.permissions}</Cell>

                    <Cell>
                        <button
                            on:click={() => {
                                licId = item.id;
                                expirationDate = item.expirationDate?.split('T')[0] ?? '';
                                version = item.version;
                                type = item.type;
                                permissions = item.permissions;
                                console.log('dead line...', expirationDate);
                                toggleModal();
                            }}
                            class="text-orange-500 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button">
                            <i class="fas fa-pen" />
                        </button>

                        <!-- <IconButton

                            on:click={() => {
                                licId = item.id;

                                expirationDate = item.expirationDate.split('T')[0];
                                version = item.version;
                                type = item.type;
                                permissions = item.permissions;
                                console.log('dead line...', expirationDate);
                                toggleModal();
                            }}>
                            <i
                                style="color:green;font-size:14px"
                                class="fa fa-pen"
                                aria-hidden="true" />
                        </IconButton> -->
                    </Cell>
                </Row>
            {/each}
        </Body>

        <Pagination slot="paginate">
            <!-- <div slot="rowsPerPage">
                    <Label>Rows Per Page</Label>

                        <Select variant="outlined" bind:value={rowsPerPage} noLabel  on:change={() => loadData(currentPage,rowsPerPage) }>
                            <Option value={10}>10</Option>
                            <Option value={25}>25</Option>
                            <Option value={100}>100</Option>
                          </Select>
                </div> -->
            <div slot="total">{start}-{end} of {total}</div>

            <IconButton
                class="material-icons"
                action="first-page"
                title="First page"
                on:click={() => (currentPage = 1)}
                disabled={currentPage === 1}>
                first_page
            </IconButton>
            <IconButton
                class="material-icons"
                action="prev-page"
                title="Prev page"
                on:click={() => {
                    currentPage = currentPage - 1;
                    loadData(customerId, currentPage, rowsPerPage);
                }}
                disabled={currentPage === 1}>
                chevron_left
            </IconButton>
            <IconButton
                class="material-icons"
                action="next-page"
                title="Next page"
                on:click={() => {
                    currentPage = currentPage + 1;
                    loadData(customerId, currentPage, rowsPerPage);
                }}
                disabled={currentPage === lastPage}>
                chevron_right
            </IconButton>
            <IconButton
                class="material-icons"
                action="last-page"
                title="Last page"
                on:click={() => (currentPage = lastPage)}
                disabled={currentPage === lastPage}>
                last_page
            </IconButton>
        </Pagination>
    </DataTable>
        </div>
    </div>
{:else}
    <SelectCustomerAlert />
{/if}
