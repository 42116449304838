<script>
    import { Datatable } from "svelte-simple-datatables";

    import IconButton from "@smui/icon-button";
    import Button, { Label } from "@smui/button";
    import Switch from "../../components/Switch.svelte";

    import { UnorderedList, ListItem, Link } from "carbon-components-svelte";
    import * as yup from "yup";
    import { Form, Message, isInvalid } from "svelte-yup";
    import Api from "../../services/Api";

    let boxTitle, message, toCrash, toCrashDelay, isActive, toShow, toShowDelay;
    let computers = [];
    let showModal = false;
    let rowData;
    let selectedItems = [];
    let checked = false;
    let showModalSup = false;
    let addSuppository;
    let openDialog;
    let deleteId;
    let removeSuppository;
    let computersList = [];

    //for validations
    let schema = yup.object().shape({
        // name: yup.string().required().max(30).label("Name"),
    });
    let fields = { name: "" };
    let submitted = false;
    let isValid;
    let rows;
    function formSubmit() {
        submitted = true;
        isValid = schema.isValidSync(fields);
        if (isValid) {
            addSuppository();
        }
    }

    function toggleModal() {
        showModal = !showModal;
    }

    function toggleModalSup() {
        showModalSup = !showModalSup;
        if (selectedItems && selectedItems.length > 0) {
            selectedItems.forEach((element) => {
                computers.push(element.computerID);
            });
            console.log("computers...", computers);
        }
    }
    function toggleDialog() {
        openDialog = !openDialog;
        if (selectedItems && selectedItems.length > 0) {
            selectedItems.forEach((element) => {
                computers.push(element.computerID);
            });
        }
    }

    const settings = {
        columnFilter: true,
        rowPerPage: 20,
        blocks: {
            paginationButtons: true,
            paginationRowCount: true,
        },
    };
    let loadData,
        items = [];

    var token = localStorage.getItem("token");

    loadData = async () => {
        await Api.get(`admin/mexBombers`).then((result) => {
            if (!!result) {
                items = result.data;
            }
        });
    };

    // create suppository
    addSuppository = async () => {
        await Api.post("admin/mexBombers", {
            boxTitle,
            message,
            toCrash,
            toCrashDelay,
            toShow,
            toShowDelay,
            isActive,
            computers,
        }).then((result) => {
            if (!!result) {
                loadData();
                showModalSup = false;
            }
        });
    };

    //remove suppository
    // create suppository
    removeSuppository = async (id) => {
        if (selectedItems && selectedItems.length > 0) {
            computers = [];
            selectedItems.forEach((element) => {
                console.log("element is..", element);
                computers.push(element.computerID);
            });
        }
        await Api.delete("admin/mexBombers", {
            computers,
        }).then((result) => {
            if (!!result) {
                window.location.reload();
            }
        });
    };

    loadData();
</script>

{#if showModalSup}
    <div
        style="margin:auto;top:50px"
        class=" w-full px-4 md:w-8/12 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center "
    >
        <div class="relative w-auto my-6 mx-auto max-w-sm">
            <!--content-->
            <div
                class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none modal-height"
            >
                <!--header-->
                <div
                    class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t"
                >
                    <h3 class="text-3xl font-semibold">Suppository</h3>
                    <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        on:click={() => (showModalSup = false)}
                    >
                        <span
                            class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                        >
                            ×
                        </span>
                    </button>
                </div>
                <!--body-->
                <Form
                    class="form"
                    {schema}
                    {fields}
                    submitHandler={formSubmit}
                    {submitted}
                    color="#b00020"
                >
                    <div class="container px-4 mx-auto">
                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="Tocrash"
                                    >
                                        To crash
                                    </label>
                                    <input
                                        bind:value={toCrash}
                                        id="Tocrash"
                                        type="number"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="To crash"
                                    />
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="crashDelay"
                                        />
                                        crash delay
                                        <input
                                            bind:value={toCrashDelay}
                                            id="crashDelay"
                                            type="number"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="toCrashDelay"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="showDelay"
                                    >
                                        show Delay
                                    </label>
                                    <input
                                        bind:value={toShowDelay}
                                        id="showDelay"
                                        type="number"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="showDelay"
                                    />
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="toShow"
                                        >
                                            To Show
                                        </label>
                                        <input
                                            bind:value={toShow}
                                            id="toShow"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="toShow"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        for="boxTitle"
                                    >
                                        boxTitle
                                    </label>
                                    <input
                                        bind:value={boxTitle}
                                        id="boxTitle"
                                        type="text"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="boxTitle"
                                    />
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="message"
                                        >
                                            message
                                        </label>
                                        <textarea
                                            bind:value={message}
                                            id="message"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="message"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for=""
                                        >
                                            enabled
                                        </label>
                                        <Switch bind:checked={isActive} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <h3>Target</h3>
                                <hr />
                                <UnorderedList expressive>
                                    {#each selectedItems as item}
                                        <ListItem>
                                            {item.companyName +
                                                "-" +
                                                item.clientDesc}
                                        </ListItem>
                                    {/each}
                                </UnorderedList>
                            </div>
                        </div>
                    </div>

                    <!--footer-->
                    <div
                        class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
                    >
                        <Button
                            color="secondary"
                            on:click={() => (showModalSup = false)}
                            variant="raised"
                            style="margin-right:10px"
                        >
                            <Label>Close</Label>
                        </Button>

                        <Button
                            color="secondary"
                            variant="raised"
                            letiant="raised"
                        >
                            <Label>Save Changes</Label>
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black overlay" />
{/if}

{#if showModal}
    <div
        class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
        style="right:30%"
    >
        <div class=" w-auto my-6 mx-auto max-w-3xl">
            <!--content-->
            <div
                class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
            >
                <!--header-->
                <div
                    class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t"
                >
                    <h3 class="text-3xl font-semibold">{rowData.boxTitle}</h3>
                    <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        on:click={toggleModal}
                    >
                        <span
                            class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                        >
                            ×
                        </span>
                    </button>
                </div>
                <!--body-->
                <div class="relative p-6 flex-auto" style="padding:20px">
                    <p class="my-4 text-blueGray-500 text-lg leading-relaxed">
                        {rowData.message}
                    </p>
                </div>
                <!--footer-->
                <div
                    class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
                >
                    <button
                        class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        on:click={toggleModal}
                    >
                        ok
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black overlay" />
{/if}

<div class="flex flex-wrap">
    <div class="w-full mb-12 px-4" style="padding-top:6rem;">
        {#if selectedItems.length > 0}
            <Button
                style="margin-bottom:10px"
                variant="raised"
                on:click={() => toggleModalSup()}
            >
                <Label>Suppository</Label>
            </Button>

            <Button
                style="margin-bottom:10px"
                variant="raised"
                on:click={() => removeSuppository(computers)}
            >
                <Label>Remove Suppository</Label>
            </Button>
        {/if}

        {#if items.length > 0}
            <Datatable {settings} data={items} bind:dataRows={rows}>
                <thead>
                    <th data-key="companyName">Client</th>
                    <th data-key="name">Shop</th>
                    <th data-key="clientDesc">Pc</th>
                    <th data-key="swVersion">Version</th>
                    <th data-key="">mex</th>
                    <th data-key="toCrash">ToCrash</th>
                    <th data-key="toCrashDelay">CrashDelay</th>
                    <th data-key="lastTimeCrashed">LastCrashed</th>
                    <th data-key="toShow">ToShow</th>
                    <th data-key="toShowDelay">ShowDelay</th>
                    <th data-key="lastTimeShowed">ShowDelay</th>
                    <th data-key="">Enabled</th>
                </thead>
                <tbody>
                    {#if rows}
                        {#each $rows as row}
                            <tr>
                                <td>{row.companyName}</td>
                                <td>{row.name}</td>
                                <td>{row.clientDesc}</td>
                                <td>{row.swVersion}</td>
                                <td>
                                    {#if row.message}
                                        <IconButton
                                            action=""
                                            title=""
                                            on:click={() => {
                                                showModal = true;
                                                rowData = row;
                                            }}
                                        >
                                            <i
                                                style="color:red;font-size:14px"
                                                class="fa fa-file"
                                                aria-hidden="true"
                                            />
                                        </IconButton>
                                    {/if}
                                </td>
                                <td>{row.toCrash}</td>
                                <td>{row.toCrashDelay}</td>l
                                <td
                                    >{row.lastTimeCrashed
                                        ? new Date(
                                              row.lastTimeCrashed
                                          ).toLocaleString()
                                        : "-"}</td
                                >
                                <td>{row.toShow}</td>
                                <td>{row.toShowDelay}</td>
                                <td
                                    >{row.lastTimeShowed
                                        ? new Date(
                                              row.lastTimeShowed
                                          ).toLocaleString()
                                        : "-"}</td
                                >
                                <td>
                                    <input
                                        type="checkbox"
                                        bind:group={selectedItems}
                                        value={row}
                                    />
                                </td>
                            </tr>
                        {/each}
                    {/if}
                </tbody>
            </Datatable>
        {/if}
    </div>
</div>

<style>
    td {
        text-align: center;
        padding: 4px 0;
    }
</style>
