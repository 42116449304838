<script>
    import Button, {Label} from "@smui/button";

    import {CUSTOMER} from "../../stores/store.js";

    import Switch from "../../components/Switch.svelte";

    import * as yup from "yup";
    import {Form, Message, isInvalid} from "svelte-yup";
    import Api from "../../services/Api";

    import SelectCustomerAlert from "../../components/SelectCustomerAlert.svelte";
    import {notify} from "svelte-notify";

    //validations
    let schema = yup.object().shape({
        firstName: yup.string().required().max(30).label("firstName"),
        lastName: yup.string().required().max(30).label("lastName"),
        email: yup.string().email().required().label("email"),
        pi: yup.string().optional().max(13).label("pi"),
        cf: yup.string().optional().max(16).label("cf"),
        cap: yup.string().optional().max(5).label("cap"),
        province: yup.string().optional().max(5).label("province"),
        address: yup.string().optional().max(80).label("address"),
    });

    let fields = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        pi: "",
        cf: "",
        cap: "",
        address: "",
    };
    let submitted = false;
    let isValid;
    let filters = "All";

    let name,
        email,
        companyName,
        phone,
        address,
        cap,
        province,
        pi,
        cf,
        alternate_email,
        enabled,
        note,
        firstName,
        lastName;

    let updatedId;
    let removeUser;
    let updateUser;
    let rowsPerPage = 10;
    let currentPage = 1;
    let total;
    let items = [];
    let loadData;
    let link = "";
    let open;

    let description;
    let userId;

    let customerId;
    let getUser;

    //get user
    getUser = async (userId) => {
        await Api.get(`admin/users/${userId}`).then(
            (result) => {
                if (!!result) {
                    fields = result.data;
                    console.log("user is..", fields)
                }
            }
        );
    };


    updateUser = async (userId) => {
        await Api.put(`admin/users/${userId}`, {
            firstName: fields.firstName,
            lastName: fields.lastName,
            password: fields.password ?? undefined,
            email: fields.email,
            companyName: fields.companyName,
            phone: fields.phone,
            address: fields.address,
            cap: fields.cap,
            province: fields.province,
            pi: fields.pi,
            cf: fields.cf,
            alternate_email: fields.alternate_email,
            note: fields.note,
            enabled: fields.enabled,
        }).then((result) => {
            if (!!result) {
                notify({
                    title: "User updated",
                    message: "User updated",
                    timeout: 1500,
                    type: "success"
                });
            }
        });
    };

    $: {
        CUSTOMER.subscribe((value) => {
            if (value) {
                customerId = value.id;
                getUser(customerId);
            }
        });
    }

    function formSubmit() {
        submitted = true;
        isValid = schema.isValidSync(fields);
        console.log(isValid);
        if (isValid) {
            updateUser(customerId);
        }
    }


</script>
{#if customerId}
    <div
            style="margin:auto;top:50px"
            class=" w-full px-4 md:w-8/12 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center "
    >
        <div class="relative w-auto my-6 mx-auto max-w-sm">
            <!--content-->
            <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none modal-height"
            >
                <!--header-->
                <div
                        class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t"
                >
                    <h3 class="text-3xl font-semibold">User</h3>
                    <button
                            class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            on:click={() => (showModal = false)}
                    >
                    <span
                            class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                    >
                        ×
                    </span>
                    </button>
                </div>
                <!--body-->
                <Form
                        class="form"
                        {schema}
                        {fields}
                        submitHandler={formSubmit}
                        {submitted}
                        color="#b00020"
                >
                    <div class="container px-4 mx-auto">
                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="firstName"
                                    >
                                        firstName
                                    </label>
                                    <input
                                            bind:value={fields.firstName}
                                            id="firstName"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="firstName"
                                    />
                                    <Message name="firstName"/>
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                for="lastName"
                                        >
                                            lastName
                                        </label>
                                        <input
                                                bind:value={fields.lastName}
                                                id="lastName"
                                                type="text"
                                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="lastName"
                                        />
                                        <Message name="lastName"/>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="flex flex-wrap my-2">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="firstName"
                                    >
                                        Password
                                    </label>
                                    <input
                                            bind:value={fields.password}
                                            id="password"
                                            type="password"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Password"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="companyName"
                                    >
                                        companyName
                                    </label>
                                    <input
                                            bind:value={fields.companyName}
                                            id="companyName"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="companyName"
                                    />
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                for="email"
                                        >
                                            email
                                        </label>
                                        <input
                                                bind:value={fields.email}
                                                id="email"
                                                type="email"
                                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="email"
                                        />
                                        <Message name="email"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="phone"
                                    >
                                        phone
                                    </label>
                                    <input
                                            bind:value={fields.phone}
                                            id="phone"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="phone"
                                    />
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                for="address"
                                        >
                                            address
                                        </label>
                                        <input
                                                bind:value={fields.address}
                                                id="address"
                                                type="text"
                                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="address"
                                        />
                                        <Message name="address"/>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="cap"
                                    >
                                        cap
                                    </label>
                                    <input
                                            bind:value={fields.cap}
                                            id="cap"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="cap"
                                    />
                                    <Message name="cap"/>
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                for="province"
                                        >
                                            province
                                        </label>
                                        <input
                                                bind:value={fields.province}
                                                id="province"
                                                type="text"
                                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="province"
                                        />
                                        <Message name="province"/>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="pi"
                                    >
                                        pi
                                    </label>
                                    <input
                                            bind:value={fields.pi}
                                            id="pi"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="pi"
                                    />
                                    <Message name="pi"/>
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                for="cf"
                                        >
                                            cf
                                        </label>
                                        <input
                                                bind:value={fields.cf}
                                                id="cf"
                                                type="text"
                                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="cf"
                                        />
                                        <Message name="cf"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="alternate_email"
                                    >
                                        alternate_email
                                    </label>
                                    <input
                                            bind:value={fields.alternate_email}
                                            id="alternate_email"
                                            type="email"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="alternate_email"
                                    />
                                </div>
                            </div>
                            <div class="w-full px-4 flex-1">
                                <div class="w-full px-4 flex-1">
                                    <div class="relative w-full">
                                        <label
                                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        >
                                            enabled
                                        </label>
                                        <Switch bind:checked={fields.enabled}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-wrap">
                            <div class="w-full px-4 flex-1">
                                <div class="relative w-full">
                                    <label
                                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            for="note"
                                    >
                                        note
                                    </label>
                                    <textarea
                                            bind:value={fields.note}
                                            id="note"
                                            type="text"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="note"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                            class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
                            style=" margin-top: 20px;
                            padding: 20px;"
                    >

                        <Button color="secondary" variant="raised" letiant="raised">
                            <Label>Save Changes</Label>
                        </Button>
                    </div>
                </Form>

                <!--footer-->
            </div>
        </div>
    </div>


{:else}
    <SelectCustomerAlert/>
{/if}

