<script>
 import DataTable, {
    Head,
    Body,
    Row,
    Cell,
    Pagination,
  } from '@smui/data-table';
  import Select, { Option } from '@smui/select';
 
  import { Label } from '@smui/common';
    import Api from "../../services/Api";
    import IconButton from "@smui/icon-button";

    import { onMount } from "svelte";
    let rowsPerPage = 10;
    let data = [];
    let currentPage = 1;
    let total;
    $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, data.length);
  $: slice = data.slice(start, end);
  $: lastPage = Math.max(Math.ceil(data.length / rowsPerPage) - 1, 0);

    $: if (currentPage > lastPage) {
        currentPage = lastPage;
    }
    let versionInstalledList;
   
   // $rows=[];

    //get version installed
    onMount(async () => {
        await Api.get("admin/dashboard/pending-updates").then((result) => {
            if (!!result) {
                data = result.data;
                total=data.length;
                console.log("bbbb", data);
            }
        });
    });

    const settings = {
        columnFilter: false,
        searchInput: false,
        rowPerPage: 10,
        blocks: {
            paginationButtons: true,
            paginationRowCount: true,
        },
    };

    let columns=["Type","Version","CreatedDate","CompanyName","Description","SwType"]
</script>


    <div
        class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
    >
        <div class="rounded-t mb-0 px-4 py-3 border-0">
            <div class="flex flex-wrap items-center">
                <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-base text-blueGray-700">
                       Pending updates
                    </h3>
                   
                </div>
            </div>
        </div>
        <div class="block w-full overflow-x-auto">
            <!-- Projects table -->

            <DataTable style="width: 100%;" table$aria-label="Pending updates">
                <Head>
                    
        
                    <Row>
                        {#each columns as column}
                            <Cell>{column}</Cell>
                        {/each}
                    </Row>
                </Head>
                <Body>
                    {#each slice as item}
                        <Row>
                            <Cell>{item.type}</Cell>
                            <Cell>{item.version}</Cell>
                            <Cell>{new Date(item.createdAt).toLocaleString()}</Cell>
                            <Cell>{item.companyName}</Cell>
                            <Cell>{item.clientDesc}</Cell>
                            <Cell>{item.swType}</Cell>
                          

                           
                        </Row>
                    {/each}
                </Body>
        
                <Pagination slot="paginate">
                    <div slot="rowsPerPage">
                            <Label>Rows Per Page</Label>
        
                            <Select variant="outlined" bind:value={rowsPerPage} noLabel>
                                <Option value={10}>10</Option>
                                <Option value={25}>25</Option>
                                <Option value={100}>100</Option>
                              </Select>
                        </div>
                    <div slot="total">{start + 1}-{end} of {total}</div>
        
                    <IconButton
                    class="material-icons"
                    action="first-page"
                    title="First page"
                    on:click={() => (currentPage = 0)}
                    disabled={currentPage === 0}>first_page</IconButton
                  >
                  <IconButton
                    class="material-icons"
                    action="prev-page"
                    title="Prev page"
                    on:click={() => currentPage--}
                    disabled={currentPage === 0}>chevron_left</IconButton
                  >
                  <IconButton
                    class="material-icons"
                    action="next-page"
                    title="Next page"
                    on:click={() => currentPage++}
                    disabled={currentPage === lastPage}>chevron_right</IconButton
                  >
                  <IconButton
                    class="material-icons"
                    action="last-page"
                    title="Last page"
                    on:click={() => (currentPage = lastPage)}
                    disabled={currentPage === lastPage}>last_page</IconButton
                  >
                </Pagination>
            </DataTable>
        </div>
    </div>

