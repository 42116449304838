
export class Authenticated {

    #model = {};


    constructor() {
        this.#model =  JSON.parse(localStorage.getItem("admin"));
    }

    get(){
        this.#model =  JSON.parse(localStorage.getItem("admin"));
        return this.#model;
    }

    /**
     *
     * @param permission : string
     * @returns boolean
     */
    can(permission){
        try {
            return this.#model.permissions.includes(permission);
        }catch (e) {
            return false;
        }
    }

}

